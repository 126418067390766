import * as React from 'react';
import { convereMailstoList, getDraftId } from '../../../Logic/Mail/draft';
import { formatEmailList } from '../../../Logic/Mail/formatter';
import { EStepKey } from '../../../Logic/Wokflow/stepLogic';
import { WizardContext } from '../../../contexts/wizard-context';
import { iEmail, isEmail } from '../../../interfaces/emailModel';
import { SaveWorkflowToStorage } from '../../../state/workflowSlice';
import { gcMaildrafts, getItem } from '../../../utilities/localStore/indexDBCalls';
import Confirm from '../../Generic/ConfirmDialog';
import '../Workflow.scss';
import EmailComposer from '../emailComposer/emailComposer';
import { WizardStepProps } from '../stepType';
import Step from '../template';

export interface EmailStepProps extends WizardStepProps {
    ActionId: number,
    Title: string,
    StepKey: EStepKey,
    setEnquirerRecipient?: boolean,
}

function ComposeEmailStep(props: EmailStepProps) {
    let {wizard, onNext, setWizard, onBack} = React.useContext(WizardContext);
    wizard = JSON.parse(JSON.stringify(wizard));
    const Enquiry = props.Enquiry;
    const [showValidationModal, setShowValidationModal] = React.useState({ visible: false, message: "" });
    const ActionId = props.ActionId;

    const nextFunc = async () => {
        let ed: iEmail = await getItem("MailDrafts", Enquiry.id+"_"+ActionId);
        if (!ed) {
            setShowValidationModal({ visible: true, message: "Please select a template." });
        }
        if (ed.subject.indexOf(Enquiry.enquiryReference) < 0) {
            ed.subject = wizard.workflow.data.EnquirerDetails.Subject + " " + Enquiry.enquiryReference;
        }
        if (ed.body == "") {
            setShowValidationModal({ visible: true, message: "Body cannot be empty" })
            return false;
        }
        if (formatEmailList(ed.to) == "") {
            setShowValidationModal({ visible: true, message: "Please ensure that the “To” recipient has at least one email address. " })
            return false;
        }
        let hasInvalidAddress = false;
        convereMailstoList(formatEmailList(ed.to)).forEach((items, i) => {
            if (isEmail(items.Address) == false) {
                alert("One or more email address not valid in the 'TO' address");
                hasInvalidAddress = true;
                return false;
            }
        })
        if (hasInvalidAddress) { return false };
        if (formatEmailList(ed.cc) != "") {
            convereMailstoList(formatEmailList(ed.cc)).forEach((items, i) => {
                if (isEmail(items.Address) == false) {
                    alert("One or more email address not valid in the 'CC' address");
                    hasInvalidAddress = true;
                    return false;
                }
            })
        }
        if (hasInvalidAddress) { return false };
        if (formatEmailList(ed.bcc) != "") {
            convereMailstoList(formatEmailList(ed.bcc)).forEach((items, i) => {
                if (isEmail(items.Address) == false) {
                    alert("One or more email address not valid in the 'BCC' address");
                    return false;
                }
            })
        }
        if (hasInvalidAddress) { return false; }

        else {
            await getDraftId(1, ed); // update the draft but why call get?!
            gcMaildrafts();

            let currentDrafts = [...wizard.mailDrafts].filter(x => x.action !== ActionId); // Don't add the same draft twice
            currentDrafts.push({
                action: ActionId, 
                draft: {
                    id: ed.maildraftId,
                    subject: ed.subject,
                    to: ed.to,
                    cc: ed.cc,
                    bcc: ed.bcc,
                }
            });
            setWizard(currentState=>{
                currentState = JSON.parse(JSON.stringify(currentState));
                currentState.mailDrafts = currentDrafts;
                return currentState;
            }) ;
            onNext();
        }
    }

    const backFunc = () => {
        let currentDrafts = [...wizard.mailDrafts].filter(x => x.action !== ActionId);
        setWizard(currentState=>{
            currentState = JSON.parse(JSON.stringify(currentState));
            currentState.mailDrafts = currentDrafts;
            return currentState;
        }) ;
        onBack()
    }

    const onTemplateApplied = (feedbackDateTime?: string) => {
        if (feedbackDateTime) {
            setWizard(currentState=>{
                currentState = JSON.parse(JSON.stringify(currentState));
                currentState.workflow.data.PendTo = feedbackDateTime;
                return currentState;
            }) ;
            SaveWorkflowToStorage(wizard);
        }
    }

    return (
        <div className={props.Popped ? "responsedEmailWithHeight" : "responsedEmail"} >
            <Step
                title={props.Title}
                nextF={nextFunc}
                backF={backFunc}
                step={props.StepKey}
                Enquiry={props.Enquiry}
                Popped = {props.Popped}
                FullWidth = {props.FullWidth}
            >
                <EmailComposer
                    showTemplates={true}
                    ActionId={ActionId}
                    Enquiry={Enquiry}
                    Communications={props.Communications?? []}
                    MISData={wizard.workflow.data.MISData}
                    onTemplateApplied={onTemplateApplied}
                    defaultRecipient={
                        props.setEnquirerRecipient ? {
                            name: wizard.workflow.data.EnquirerDetails.FullName,
                            address: wizard.workflow.data.EnquirerDetails.Email
                        } :
                        undefined
                    }
                />
            </Step>

       

            {showValidationModal.visible && <Confirm
                title={"Warning"}
                onCancel={() => { setShowValidationModal({ visible: false, message: "" }) }}
                onConfirm={() => { setShowValidationModal({ visible: false, message: "" }) }}
                messageType={"warn"}
            ><>{showValidationModal.message}</></Confirm>}
        </div>
    );
}


export default ComposeEmailStep

