import { createSlice} from '@reduxjs/toolkit'
import { iCommunicationCache } from '../interfaces/iEnquiry';
import { dateTimeFormatLong } from '../components/Enquiries/enquiry/enquiry';

const initialState  =  {data: [] as iCommunicationCache[]}

export const communicationCache = createSlice({
    name: 'updateCommunicationCache',
    initialState,
    reducers: {
        updateCommunicationCache: (state, action) => {
            var map = new Map(state.data.map((obj) => [obj.enquiryId, obj]));
            map.set(action.payload.enquiryId ,{enquiryId:action.payload.enquiryId,communications:action.payload.communications,cachedAt: action.payload.cachedAt});
            state.data = Array.from(map.values());
          },
    },
});

// Action creators are generated for each case reducer function
export const { updateCommunicationCache} = communicationCache.actions

export default communicationCache;