import { Fragment, useEffect, useState } from "react"
import Step from '../template'
import AvancedSearch from "../../AdvancedSearch";
import { BsChevronRight } from "react-icons/bs";
import { GetSearchResults } from "../../../Logic/Search";
import { getEnquiries, getEnquiriesMap } from "../../../utilities/localStore/calls";
import EnquiryWrapper from "./enquiryWrapper";
import Enquiries from "../../Enquiries";
import { SortEnquiriesParams, sortByField, sortOwner, sortStatus } from "../../../utilities/sort";
import { iEnquiry } from "../../../interfaces/iEnquiry";
import { AdvancedSearchPM } from "../../../interfaces/AdvancedSearch/AdvancedSearchPM";
import { EStepKey } from "../../../Logic/Wokflow/stepLogic";
import { EEnquiryAction } from "../../../interfaces/wizard";
import { WizardStepProps } from "../stepType";
import React from "react";
import { WizardContext } from "../../../contexts/wizard-context";
import { useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import { getEnquiryById } from "../../../interfaces/enquiryList";
import EnquiryGrid from '../../Enquiries/enquiryGrid';
import DataTable, { dataColumn } from '../../Generic/DataTable';
import { BsPaperclip, BsThermometerHalf } from 'react-icons/bs';
import Enquiry from "../../Enquiries/enquiry/enquiry";
import { Bell } from 'react-feather';
import { dateTimeFormat222 } from '../../Enquiries/enquiry/enquiry';
import Statuses from '../../../interfaces/Statuses';
import { ActivateWizardModule } from '../../../Logic/Enquiries/activateWizard';
import { useContextMenu } from '../../../hooks/useContextMenu';


const Index = (props: WizardStepProps) => {
	const { setContextMenuState, closeContextMenu } = useContextMenu();
    const showDataTableContextMenu = (key: string, x: number, y: number) => {
        closeContextMenu();
        setContextMenuState({ keys: [(parseInt(key))], visible: true, x: x, y: y })
    }
    let {wizard, onNext, setWizard, onBack} = React.useContext(WizardContext);
    wizard = JSON.parse(JSON.stringify(wizard));
    const initialAccordions = [
		{id: 0, active: true, disabled: false},
		{id: 1, active: false, disabled: true}
	]

	const [accordions, setAccordions] = useState(initialAccordions);
	const [activeTab, setActiveTab] = useState(0);
	const [activeEnquiry, setActiveEnquiry] = useState(0);
    const [activeEnquiryIndex, setActiveEnqIndex] = useState(0);
	const [searchResults, setSearchResults] = useState<number[]>([]);

	let enSort: SortEnquiriesParams = {orderBy: null}
	const [sortBy, setSortBy] = useState<SortEnquiriesParams>(enSort)

	function openAccordion(accs: any[], id: number) {
		return accs.slice().map(acc => {
			if (acc.id === id) {
				return {...acc, active: true, disabled: false}
			}
			else {return {...acc, active: false}}
		});
	}

	const onSearch = async (query: AdvancedSearchPM) => {
        let results = await GetSearchResults(query);
		results = {...results, Results: results.Results.filter(v => v!==wizard.EnquiryId)}
		wizard.workflow.LinkToEnquiry.searchResults = results
		wizard.workflow.data.ExistingEnquiryID = 0

		setSearchResults(results.Results);
		setAccordions(accs => openAccordion(accs, 1));
		//setActiveEnquiry(0);
        setWizard(currentState=>{
            currentState = JSON.parse(JSON.stringify(currentState));
            currentState.workflow.LinkToEnquiry.searchResults = results;
            currentState.workflow.data.ExistingEnquiryID = 0
          return currentState;
        }) ;
    }

	function loadQuery() {
		return wizard.workflow.LinkToEnquiry.searchResults?.Query
    };

	function handleHeaderClick(orderBy: string):any {
		if (sortBy?.orderBy === orderBy) {
			enSort = {orderBy: orderBy, desc: !sortBy?.desc}
		}
		else enSort = {orderBy: orderBy}

		let enquiries: iEnquiry[] = getEnquiries(searchResults) 
                                    .filter(e => e !== undefined)
                                    .map(x => x!)   
		if (enquiries !== undefined && enSort.orderBy) {
			if (enSort.orderBy === "owner") {
				enquiries = sortOwner(enquiries, enSort.desc)
			}
			else if (enSort.orderBy === "status") {
				enquiries = sortStatus(enquiries, enSort.desc)
			}
			else {
				enquiries = sortByField(enquiries, enSort.orderBy, enSort.desc)
			}
			const ids = enquiries.map(e => e.id)
			setSearchResults(ids);
		}
		setSortBy(enSort)
	}
	 
	function onEnquiryClick(enquiryId: number) {
		wizard.workflow.data.ExistingEnquiryID = enquiryId;
		// SaveWorkflowToStorage(wizard);
        setWizard(currentState=>{
            currentState = JSON.parse(JSON.stringify(currentState));
            currentState.workflow.data.ExistingEnquiryID = enquiryId;
          return currentState;
        }) ;
		setActiveEnquiry(enquiryId)
		setActiveTab(1)
	}

	function onEnquiryDoubleClick(enquiryId: number) {
		onEnquiryClick(enquiryId)
		nextFunc();
	}

	function persistQuery(persistQ: AdvancedSearchPM) {
		wizard.workflow.LinkToEnquiry.searchResults = {
			Query: persistQ,
			Results: searchResults,
		}
        setWizard(currentState=>{
            currentState = JSON.parse(JSON.stringify(currentState));
            currentState.workflow.LinkToEnquiry.searchResults = {
                Query: persistQ,
                Results: searchResults,
            };
          return currentState;
        }) ;
    }

    function setInitialQuery(initQuery: AdvancedSearchPM) {
        initQuery.EmailAddress.Enabled = true;
        initQuery.EmailAddress.Value = wizard.workflow.data.EnquirerDetails.Email;

        initQuery.EnquirerName.Enabled = true;
        initQuery.EnquirerName.Value = wizard.workflow.data.EnquirerDetails.FullName;

        initQuery.Mailbox.Enabled = true;
        initQuery.Mailbox.Value = props.Enquiry.mailbox;
    }

	useEffect(() => {
 
		if (wizard.workflow.LinkToEnquiry.searchResults) {
			setSearchResults(wizard.workflow.LinkToEnquiry.searchResults.Results);
		}
        const activeE = wizard.workflow.data.ExistingEnquiryID ? wizard.workflow.data.ExistingEnquiryID : 0
		if (wizard.workflow.LinkToEnquiry.searchResults?.Results?.length) {
			setAccordions(accs => openAccordion(accs, 1));
            var newIndex = wizard.workflow.LinkToEnquiry.searchResults.Results.findIndex(x=> x==activeE);
            newIndex = newIndex <0? 0 : newIndex;
            setActiveEnqIndex(newIndex)
		}
		setActiveEnquiry(activeE);
        return () => { }
	}, [props.Enquiry])

	const nextFunc = () => {
		if (wizard.workflow.data.ExistingEnquiryID && wizard.workflow.data.ExistingEnquiryID > 0) {
			wizard.workflow.data.EnquiryAction = EEnquiryAction.LinkToExisting;
            setWizard(currentState=>{
                currentState = JSON.parse(JSON.stringify(currentState));
                currentState.workflow.data.EnquiryAction = EEnquiryAction.LinkToExisting;
              return currentState;
            }) ;
            onNext()
		}
		else {
			alert("Please choose an enquiry to link")
		}
	}

	const backFunc = () => {
		 onBack()
	}
 
 
	function handleFirstAccordion() {
		setAccordions(accs => openAccordion(accs, 0));
	//	setActiveEnquiry(0)
		setActiveTab(0)
        setWizard(currentState=>{
            currentState = JSON.parse(JSON.stringify(currentState));
            currentState.workflow.data.ExistingEnquiryID = 0
          return currentState;
        }) ;
	}
let AllColumns:dataColumn<iEnquiry>[] =[
    {
        title: <>&nbsp;</>,
        key:"verticalSPacer",
        getData: () => {
            return <div className="verticalBorder">
                <div style={{ height: "40px", width: "8px", marginTop: "-20px", marginBottom: "-40px", float: "left", marginLeft: "-6px" }} >&nbsp;</div>
            </div>
        },
        width: "10px",
        canResize: false,
        sort: undefined,
        textAlignment: "left",
        minWidth: "10px"
    },
    {
        title: <BsThermometerHalf></BsThermometerHalf>,
        toolTip:"Priority",
        key:"priority",
        getData: (data:iEnquiry) => {
            return <div className="sla-priority-bar" style={{marginLeft:"2px"}}>
                <div style={{ height: ((255 - data.priority) / 255.0) * 100.0 + "%" }} className="sla-priority--value">&nbsp;</div>
            </div>
        },
        width: "20px",
        canResize: false,
        sort: {
            direction: undefined,
            sortMethod: () => { handleHeaderClick("priority") },
            displayIcon: false
        },
        textAlignment: "left",
        minWidth: "15px"
    },
    {
        title: <Bell size={12}></Bell>,
        key:"hasAlarm",
        toolTip:"Alarms",
        getData: (data:iEnquiry) => { return data.hasAlarm == true ? <Bell size={12}></Bell> : "" },
        width: "18px",
        canResize: false,
        sort: {
            direction: undefined,
            sortMethod: () => { handleHeaderClick("hasAlarm") },
            displayIcon: false
        },
        textAlignment: "left",
        minWidth: "15px"
    },
    {
        title: <BsPaperclip></BsPaperclip>,
        key:"hasUnworkedAttachments",
        toolTip:"Unworked Attachments",
        getData: (data:iEnquiry) => { return data.hasUnworkedAttachments === true ? <BsPaperclip></BsPaperclip> : "" },
        width: "20px",
        canResize: false,
        sort: {
            direction: undefined,
            sortMethod: () => { handleHeaderClick("hasUnworkedAttachments") },
            displayIcon: false
        },
        textAlignment: "left",
        minWidth: "10px"
    },

    {
        title: <>Reference</>,
        key:"enquiryReference",
        toolTip:"Reference",
        getData: (data:iEnquiry) => { return data.enquiryReference },
        width: "130px",
        canResize: true,
        sort: {
            direction: undefined,
            sortMethod: () => { handleHeaderClick("enquiryReference") },
            displayIcon: true
        },
        textAlignment: "left",
        minWidth: "90px"
    },
    {
        title: <>Owner</>,
        key:"owner",
        toolTip:"Owner",
        getData: (data:iEnquiry) => { return data.owners.map((item) => { return `${item.lastname}, ${item.firstname}` }).join('; ') },
        width: "20%",
        
        canResize: true,
        sort: {
            direction: undefined,
            sortMethod: () => { handleHeaderClick("owner") },
            displayIcon: true
        },
        textAlignment: "left",
        minWidth: "80px"
    },
    {
        title: <>Enquirer</>,
        key:"enquirerName",
        toolTip:"Enquirer",
        getData: (data:iEnquiry) => { return data.enquirerName },
        width: "20%",
        canResize: true,
        sort: {
            direction: undefined,
            sortMethod: () => { handleHeaderClick("enquirerName") },
            displayIcon: true
        },
        textAlignment: "left",
        minWidth: "80px"
    }, 

    {
        title: <>Received</>,
        key:"dateReceived",
        toolTip:"Received",
        getData: (data:iEnquiry) => { return dateTimeFormat222(data.dateReceived )},
        width: "20%",
        canResize: true,
        sort: {
            direction: undefined,
            sortMethod: () => { handleHeaderClick("dateReceived") },
            displayIcon: true
        },
        textAlignment: "left",
        minWidth: "80px"
    },
    {
        title: <>Expiry</>,
        key:"activeSlaCycle.timeout",
        toolTip:"Expiry",
        getData: (data:iEnquiry) => { return dateTimeFormat222(data.activeSlaCycle?.timeout? data.activeSlaCycle?.timeout :"")},
        width: "20%",
        canResize: true,
        sort: {
            direction: undefined,
            sortMethod: () => { handleHeaderClick("activeSlaCycle.timeout") },
            displayIcon: true
        },
        textAlignment: "left",
        minWidth: "80px"
    }, 

    {
        title: <>Status</>,
        key:"status",
        toolTip:"Status",
        getData: (data:iEnquiry) => { return Statuses.Name(data.status) },
        width: "35%",
        canResize: true,
        sort: {
            direction: undefined,
            sortMethod: () => { handleHeaderClick("status") },
            displayIcon: true
        },
        textAlignment: "left",
        minWidth: "80px"
    },
    {
        title: <>Subject</>,
        key:"subject",
        toolTip:"Subject",
        getData: (data:iEnquiry) => { return data.subject},
        width: "35%",
        canResize: true,
        sort: {
            direction: undefined,
            sortMethod: () => { handleHeaderClick("subject") },
            displayIcon: true
        },
        textAlignment: "left",
        minWidth: "80px"
    },
   
    {
        title: <>Completed</>,
        key:"dateCompleted",
        toolTip:"Completed",
        getData: (data:iEnquiry) => { return  data.dateCompleted?  dateTimeFormat222(data.dateCompleted):""},
        width: "20%",
        canResize: true,
        sort: {
            direction: undefined,
            sortMethod: () => { handleHeaderClick("activeSlaCycle.timeout") },
            displayIcon: true
        },
        textAlignment: "left",
        minWidth: "80px"
    },
    {
        title: <>Items</>,
        key:"workItems",
        toolTip:"Items",
        getData: (data:iEnquiry) => { return data.workItems },
        width: "50px",
        canResize: false,
        sort: {
            direction: undefined,
            sortMethod: () => { handleHeaderClick("workItems") },
            displayIcon: false
        },
        textAlignment: "right",
        minWidth: "50px",
        maxWidth: "50px"
    },
]

for (let col of AllColumns) {
    if(sortBy && sortBy.orderBy == col.key)    {
       if(col.sort){
            col.sort.direction = sortBy?.desc ? "desc" : "asc"
        }
    }
}

const keydownHandle = (event: React.KeyboardEvent<HTMLTableRowElement>) => {
    if (event == null)
        return;
    if (event.key != "ArrowUp" && event.key != "ArrowDown")
        return;
    let results =getEnquiries(searchResults);
    const activeEnquiryIndex = results.findIndex(x => x.id === activeEnquiry);
    let nextEnquiry = 0;
    let nextEnquiryIndex;
    if (event.key == "ArrowUp") {
        nextEnquiryIndex = activeEnquiryIndex > 0 ? activeEnquiryIndex - 1 : activeEnquiryIndex;
    }
    else {
        nextEnquiryIndex = activeEnquiryIndex < results.length - 1 ? activeEnquiryIndex + 1 : activeEnquiryIndex;
    }

    nextEnquiry = results[nextEnquiryIndex].id;
    if (activeEnquiry != nextEnquiry) {
        const enqModule = new ActivateWizardModule();
        enqModule.click = 0;
        enqModule.showEnquiry(nextEnquiry);
    }
}
const getExpiryColour = (e:iEnquiry):string => {
    if (e.activeSlaCycle != null && !Statuses.IsCompleted(e.status)) {
        let reminderDate = e.activeSlaCycle.reminder? new Date( e.activeSlaCycle?.reminder) : "";
        let alarmDate = e.activeSlaCycle.alarm ? new Date(e.activeSlaCycle?.alarm) : "";
        let timeoutDate = e.activeSlaCycle.timeout ? new Date(e.activeSlaCycle?.timeout) : "";
        const currentDate = new Date();
        if (currentDate < reminderDate) {
            return " expiry-green"
        }
        else if (currentDate >= reminderDate && currentDate < alarmDate) {
            return " expiry-orange"
        }
        else if (currentDate >= alarmDate && currentDate < timeoutDate) {
            return " expiry-red"
        }
        else if (currentDate > reminderDate) {
            return " expiry-purple"
        }
        else{return "";}
    } else {
        return "";
    }
}
	return (
		<Fragment>
			<Step
				title="Search for and Select an Enquiry"
				backF={backFunc}
				nextF={activeEnquiry > 0 ? nextFunc : undefined}
				step={EStepKey.LINK_TO_ENQUIRY_STEP}
				Enquiry={props.Enquiry}
                Popped = {props.Popped}
                FullWidth = {props.FullWidth}
			>
				<div className={"accord my-2" + (accordions.find(acc => acc.id===0)?.active  ? " active" : "")}>
					<div className="accord-head" onClick={handleFirstAccordion}>
						<i><BsChevronRight className="accord-arrow"/></i>
						<b className="required col-xs-6 mx-2">Search Enquiries</b>
					</div>
					<div className="accord-content">
						<div className="search-fields">
							<AvancedSearch
								onClose={() => { }}
								onSearch={onSearch}
								loadQuery={loadQuery}
								persistQuery={persistQuery}
                                queryInitInterceptor={setInitialQuery}
							/>
						</div>
					</div>
				</div>
				{!accordions.find(acc => acc.id===1)?.disabled && <div className={"accord my-2" + (accordions.find(acc => acc.id===1)?.active ? " active" : "")}>
					<div className="accord-head" onClick={() => setAccordions(accs => openAccordion(accs, 1))}>
						<i><BsChevronRight className="accord-arrow"/></i>
						<b className="required col-xs-6 mx-2">Search Results</b>
					</div>
					<div className="accord-content">
						<div className="enquiries-bar-nav">
							<div className="select-enq limit-text mb-2">Select the enquiry to link the communication to</div>

							<div className="buttons-sect">
								<button className={"g-default-tab-padding g-tab"+(activeTab===0 ? "-selected" : "")} onClick={() => setActiveTab(0)}>Enquiry List</button>
								{activeEnquiry > 0 && <button className={"g-default-tab-padding g-tab"+(activeTab===1 ? "-selected" : "")} onClick={()=>  setActiveTab(1)}>Selected Enquiry</button>}
							</div>
						</div>
						<div className="enquiries-result">
							{activeTab === 0 && <>
								{searchResults ?  <DataTable data={getEnquiries(searchResults)} theme="dataTable_default"
                    columns={AllColumns} 
                    uniqueIdentifiyer={(x)=>{return x.id.toString()}} 
                    onRowClick={(x: string) =>{
                             onEnquiryClick(parseInt(x));
                    }
                    } 
                    onRowDblClick={(x: string) =>{
                        
                            onEnquiryDoubleClick(parseInt(x));
                        
                    }}
                    selectedItems={[activeEnquiry.toString()]}
                    selectedItem={activeEnquiry.toString()}
                    contextMenu={showDataTableContextMenu} keyPress={keydownHandle}
                    spacerRow={3}  
                    getCustomClass={(e)=>{
                        let classes =  getExpiryColour(e) ;
                        classes += (!Statuses.IsCompleted(e.status)) && e.hasNew ? " has-new" : "";
                        return classes;
                    }}
                    lastItemInteracted={activeEnquiryIndex}
                ></DataTable> :
									<div style={{ padding: "5px 10px", fontSize: "9pt" }}>No results found</div>
								}
							</>}
							{activeTab === 1 && <>
							{activeEnquiry > 0 && <EnquiryWrapper
							FullWidth={false}
							enquiry={getEnquiryById(activeEnquiry)?? {} as iEnquiry} />}
							</>}
						</div>
					</div>
				</div>}
			</Step>

 
		</Fragment>
	)
}

export default Index;