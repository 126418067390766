import { getTenantToken, UpdateAuthTokens } from "../Auth/TokenManager";
import { showHideLoader } from "../../utilities/loading";
import { logErrorToService } from "../../error-boundary/logerror";

export interface iEmsApiResponse {
    isOk: boolean;
    status: number;
    statusText: string;
    validationErrors?: iValidationError | undefined;
    serverError?: iError | undefined;
    errorLogID?: string | undefined;
}

export interface iEmsApiResponseBody<TResponse> extends iEmsApiResponse {
    data?: TResponse | undefined;
}

export interface iValidationError {
    title: string;
    errors: {
        [key: string]: string[];
    };
}

 export interface iError {
    type: string;
    title: string;
    status: number;
    traceId: string;
 }

export async function CallEmsApi<TResponse>(
    method: "GET" | "POST" | "PUT" | "DELETE",
    url: string,
    body?: any,
): Promise<iEmsApiResponseBody<TResponse>> {
    showHideLoader("flex");

    const emsResponse: iEmsApiResponseBody<TResponse> = {
        isOk: false,
        status: 0,
        statusText: "",
        data: undefined,
        validationErrors: undefined,
        serverError: undefined,
        errorLogID: undefined,
    };

    let sURL = process.env.REACT_APP_API
    if (!sURL || sURL.length === 0) {
        var str = window.location;
        let stringtoReplaces =  
               str.origin.substring(str.protocol.length+2, str.origin.indexOf(".", str.protocol.length+2))
        sURL =  str.origin.replace(stringtoReplaces,"api");
    }
    if (!sURL.endsWith("/")) {
        sURL += "/";
    }

    sURL = sURL+url;
    
    
    const headers = new Headers();
    headers.append("Authorization", "bearer " + getTenantToken());

    if (body) {
        headers.append("Content-Type", "application/json");
    }

    var fetchOptions: any = {};
    fetchOptions.method = method;
    fetchOptions.headers = headers;
    if (body) {
        fetchOptions.body = JSON.stringify(body);
    }

    var response = await fetch(sURL, fetchOptions);
    if (response.status === 401) {
        await UpdateAuthTokens();
        response = await fetch(sURL, fetchOptions);
    }

    emsResponse.isOk = response.ok;
    emsResponse.status = response.status;
    emsResponse.statusText = response.statusText;

    if (response.ok && response.status === 200) {
        emsResponse.data = await response.json();
    } else if (response.status === 400) {
        emsResponse.validationErrors = await response.json();
        emsResponse.errorLogID = logErrorToService(new Error(`400 - ${JSON.stringify(emsResponse.validationErrors)}`));
    } else if (response.status === 500) {
        emsResponse.serverError = await response.json();
        emsResponse.errorLogID = logErrorToService(new Error(`500 - ${JSON.stringify(emsResponse.serverError)}`));
    } else if (!response.ok) {
        emsResponse.errorLogID = logErrorToService(new Error(`${response.status} - ${response.statusText}`));
    }

    showHideLoader("none");
    return emsResponse;
}
