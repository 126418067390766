import { useState } from 'react';
import { BsX } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { removeNewEnquiryId } from '../../../Logic/Enquiries/activateWizard';
import { LoadActiveList, ReloadActiveList } from '../../../Logic/Enquiries/Load';
import { setActiveComm } from '../../../state/activeComm';
import { setActiveEnquiry } from '../../../state/activeEnquiry';
import { changeConnection } from '../../../state/connection';
import { setShowEnquiry, setShowWizard } from '../../../state/restrictedViewSlice';
import { setWorkflowVisible } from '../../../state/secondColSlice';
import { RootState, useAppDispatch } from '../../../state/store';
import { removeTab, setTabVisibility } from '../../../state/tabRow';
import { GetWorkflowFromStorage, setState } from '../../../state/workflowSlice';
import { resetSort } from '../../../utilities/sort';
import { closeConnection, keepTheConnectionAlive } from '../../../utilities/userProcessing';
import Confirm from '../../Generic/ConfirmDialog';
import { wizardMessage } from '../../Workflow';
import './tabNavigations.scss';
 

function Tab(props: any) {
    const dispatch: Dispatch<any> = useAppDispatch();
    const UserInfo = useSelector((state: RootState) => state.UserInformation);
    const tabs = useSelector((state: RootState) => state.tabRow.tabs);

    const makeTabActive = async (enquiryId: number, label: string) => {
        resetSort();
        
        const workflow = GetWorkflowFromStorage(enquiryId);
        if (workflow) {
            dispatch(setTabVisibility(enquiryId));

            keepTheConnectionAlive(enquiryId).then(()=>{
                //dispatch(setCommToView({...ToGenericComm(workflow.lastCommunication!)}));
                dispatch(setState(workflow));
                dispatch(setWorkflowVisible(true));
                dispatch(setActiveEnquiry(enquiryId));
                if (!props.FullWidth) {  dispatch( setShowEnquiry(true));}
            }
                
            ).catch((err)=>{
                dispatch(changeConnection(false));
                //dispatch(setCommToView({...ToGenericComm(workflow.lastCommunication!)}));
                dispatch(setState(workflow));
                dispatch(setWorkflowVisible(true));
                dispatch(setActiveEnquiry(enquiryId));
                if (!props.FullWidth) {  dispatch( setShowEnquiry(true));}
            });
        } else {
            if (!tabs[0].active) {
                await LoadActiveList();
            }
             dispatch(setTabVisibility(enquiryId));

             dispatch(setActiveComm(0));
             dispatch(setShowEnquiry(false));
             dispatch(setShowWizard(false));
             dispatch(setWorkflowVisible(false));
        }
    }
	 
const deleteTab = async (enquiryId: number) => {
    showConfirmDialogue(enquiryId,
        () => {
            closeConnection(enquiryId).then(async () => {
                removeNewEnquiryId(enquiryId)
                dispatch(setShowEnquiry(false));

                await ReloadActiveList()

                dispatch(removeTab(enquiryId));
                
            }).catch((err) => {
                console.log(err)
                return;
            })
        }, true);
    }

    const [dialogueShown, setDialogueShown] = useState({
        enquiryId: 0,
        okFunc: Function(),
        isShown: false
      })
      const showConfirmDialogue = (enquiryId: number, okFunc: Function, isShown: boolean) => {
        setDialogueShown({
          enquiryId,
          okFunc,
          isShown
        })
      }

    return (
        <>
        <div className={props.active ? "navTab active" : "navTab"} key={props.enquiryId}>
             <div className={props.enquiryId > 100 ? "" : "fixedWidth"}>
                <div onClick={() => { makeTabActive(props.enquiryId, props.label) }} id={"tab-" + props.enquiryId}>{props.label}</div>
                {props.enquiryId > 100 ? <div onClick={() => { deleteTab(props.enquiryId) }}><BsX style={{ fontSize: "15px" }}></BsX></div> : ""}
            </div>
        </div>
            {dialogueShown.isShown && <Confirm
                title={"Warning"}
                onCancel={() => showConfirmDialogue(0, dialogueShown.okFunc, false)}
                onConfirm={() => dialogueShown.okFunc()}
                messageType={"warn"}
            >{[wizardMessage]}</Confirm>}
        </>
    )

};
export default Tab;