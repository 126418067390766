import { AdvancedSearchPM } from "../interfaces/AdvancedSearch/AdvancedSearchPM";
import { AdvancedSearchRequest, GetAdvancedSearchResults, SearchClause } from "./EmsAPI/Enquiries";
import { AdvancedSearchResults } from "../interfaces/AdvancedSearch/AdvancedSearchResults";
import { SearchFieldHistory } from "../interfaces/AdvancedSearch/SearchFieldHistory";
import { SearchFieldPM } from "../interfaces/AdvancedSearch/SearchFieldPM";
import { iEnquiry } from "../interfaces/iEnquiry";
import { iUser } from "../interfaces/iUser";
import { updateEnquiriesCache } from "../state/enquiriesCache";
import store from "../state/store";
import { getSessionValue, saveSessionValue } from "../utilities/localStore/calls";

export async function GetSearchResults(query: AdvancedSearchPM) {
    var request = MapToRequest(query);
    const response = await GetAdvancedSearchResults(request);
    if (!response.isOk) {
        throw new Error(`${response.status} returned from server.`);
    }
    if (!response.data) {
        throw new Error("No data returned from server.");
    }

    var searchResults: AdvancedSearchResults = {
        Query: query,
        LastRunQuery:query,
        Results: [],
    }
    if(response.data.enquiries.length>0){
            let SearchResultsList: number[] = [];
            SearchResultsList = response.data.enquiries.flatMap((x:iEnquiry)=> x.id);
            store.dispatch(updateEnquiriesCache(response.data.enquiries));
            searchResults.Results = SearchResultsList;
     }
    return searchResults;
}

export function MapToRequest(pm: AdvancedSearchPM): AdvancedSearchRequest {

    var request: AdvancedSearchRequest = {
        OtherReferenceClause: MapClause(pm.OtherReference.IsContains),
        EnquirerNameClause: MapClause(pm.EnquirerName.IsContains),
        EmailAddressClause: MapClause(pm.EmailAddress.IsContains),
        SubjectClause: MapClause(pm.Subject.IsContains),
        BodyClause: MapClause(pm.Body.IsContains),
        ReturnFirst: parseInt(pm.ReturnFirst.Value),
    };

    if (pm.EnquiryReference.Enabled) {
        request.EnquiryReference = pm.EnquiryReference.Value;
    }

    if (pm.OtherReference.Enabled) {
        request.OtherReference = pm.OtherReference.Value;
    }

    if (pm.EnquirerName.Enabled) {
        request.EnquirerName = pm.EnquirerName.Value;
    }

    if (pm.EmailAddress.Enabled) {
        request.EmailAddress = pm.EmailAddress.Value;
    }

    if (pm.Subject.Enabled) {
        request.Subject = pm.Subject.Value;
    }

    if (pm.Body.Enabled) {
        request.Body = pm.Body.Value;
    }

    if (pm.Owner.Enabled) {
        if (pm.Owner.Value?.id !== undefined && typeof pm.Owner.Value?.id === "number") {
            request.Owner = pm.Owner.Value.id;
        }
    }

    if (pm.EnquiryStatus.Enabled) {
        request.EnquiryStatus = parseInt(pm.EnquiryStatus.Value);
    }

    if (pm.Mailbox.Enabled) {
        request.Mailbox = parseInt(pm.Mailbox.Value);
    }

    if (pm.ReceivedAfter.Enabled) {
        request.ReceivedAfter = pm.ReceivedAfter.Value;
    }

    if (pm.ReceivedBefore.Enabled) {
        request.ReceivedBefore = pm.ReceivedBefore.Value;
    }

    return request;
}

function MapClause(isContains: boolean | undefined): SearchClause {
    return isContains ? SearchClause.Contains : SearchClause.StartsWith;
}

function GetNewSearchFieldHistory(): SearchFieldHistory {
    return {
        EnquiryReference: new Array<string>(),
        OtherReference: new Array<string>(),
        EnquirerName: new Array<string>(),
        EmailAddress: new Array<string>(),
        Subject: new Array<string>(),
        Body: new Array<string>(),
        Owner: new Array<iUser>(),
    };
}

export function GetSearchFieldHistory(): SearchFieldHistory {
    let history = getSessionValue<SearchFieldHistory>("searchFieldHistory");

    if (!history) {
        history = GetNewSearchFieldHistory();
    }

    return history;
}

export function UpdateSearchFieldHistory(pm: AdvancedSearchPM) {

    var history = GetSearchFieldHistory();

    UpdateFieldHistory(history.EnquiryReference, pm.EnquiryReference);
    UpdateFieldHistory(history.OtherReference, pm.OtherReference);
    UpdateFieldHistory(history.EnquirerName, pm.EnquirerName);
    UpdateFieldHistory(history.EmailAddress, pm.EmailAddress);
    UpdateFieldHistory(history.Subject, pm.Subject);
    UpdateFieldHistory(history.Body, pm.Body);
    
    UpdateOwnerHistory(history.Owner, pm.Owner.Value);

    saveSessionValue("searchFieldHistory", history);
}



function UpdateFieldHistory(history: string[], field: SearchFieldPM) {

    if (field.Value === undefined)
        return;

    let stringVal = field.Value as string;
    if (stringVal == undefined)
        return;

    if (stringVal.replace(/\s/g, '').length === 0) // is empty or white space
        return;
        
    const existingIndex = history.indexOf(stringVal);
    if (existingIndex > -1) {
        history.splice(existingIndex, 1);
    }

    history.splice(0, 0, stringVal);
}

function UpdateOwnerHistory(history: iUser[], value: iUser) {
    if (value === undefined) {
        return;
    }

    let existingIndex = history.findIndex(x => x.id === value.id);
    if (existingIndex > -1) {
        history.splice(existingIndex, 1);
    }

    history.splice(0, 0, value);
}