import { CallEmsApi, iEmsApiResponseBody } from "./FetchExtension";

export interface CreateNoteRequest {
    enquiryID: number,
    priority?: 1 | 2 | 3 | 4 | 5,
    subject?: string,
    body?: string,
    AttachmentDraftID?: number
}

export interface iCreateNoteResponse {
    communicationID: number,
    noteID: number,
}

export async function CreateNote(request: CreateNoteRequest): Promise<iEmsApiResponseBody<iCreateNoteResponse>> {
    return await CallEmsApi<iCreateNoteResponse>("POST", "Note", request);
}