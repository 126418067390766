import { iUser } from "../../interfaces/iUser";
import { Get } from "../../utilities/apiCall";

export async function GetMailboxUsers(mailboxes: number[]): Promise<iUser[]> {
    let mailboxQuery = "";
    for (let mailbox of mailboxes) {
        mailboxQuery += `mailboxId=${mailbox}&`;
    }

    const result: iUser[] = (await Get("Users?" + mailboxQuery +"filterAssignable=true")).users;

    return result.sort((uA, uB) => {
        const aVal = `${uA.firstname} ${uA.lastname}`.toLowerCase();
        const bVal = `${uB.firstname} ${uB.lastname}`.toLowerCase();
        return aVal < bVal ? -1 : aVal > bVal ? 1 : 0;
    });
}