import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { EnquiryGridSelectionContext, gridSelectionProperties } from "../../../contexts/enquiry-grid-selection.context";
import { iEnquiry } from "../../../interfaces/iEnquiry";
import { getPreference, iPreference, savePrefence } from "../../../interfaces/preference";
import { getLastTouched, saveLastTouched } from "../../../Logic/LastTouched/misc";
import { RootState } from "../../../state/store";
import { getActiveListEnquiries, saveActiveListEnquiries, saveSessionValue } from "../../../utilities/localStore/calls";
import { sortByField, SortEnquiriesParams, sortOwner, sortStatus } from "../../../utilities/sort";
import EnquiryGrid from "../enquiryGrid";
import "./index.scss";

function MyEnquiries({ FullWidth }: { FullWidth: boolean }) {
    const [busyLoading, setLoading] = useState(true);
    const [allEnquiries, setAllenquiries] = useState<Array<iEnquiry>>([]);
    const activeEnquiry = useSelector((state: RootState) => state.activeEnquiry);
    const LastTouched = getLastTouched();
    const enquiriesCache = useSelector((state: RootState) => state.EnquiryCache.data);
    const [sortBy, setSortBy] = useState<SortEnquiriesParams>();
    const { selectionProperties, SetEnquiryGridSelectionProperties } = useContext(EnquiryGridSelectionContext);

    useEffect(() => {
        const activeList = getActiveListEnquiries() ?? [];

        const enqCache = new Map(enquiriesCache.map(x => [x.id, x]));
        const updatedEnquiries = activeList
            .map(id => enqCache.get(id))
            .filter(x => x)
            .map(x => x!);

        const LastTouched = getLastTouched();
        const lastSort = LastTouched.MyEnquiries.EnquiryLists.find(l => l.Name === LastTouched.MyEnquiries.ActiveList)
            ?.orderBy ?? { orderBy: null };

        setAllenquiries(updatedEnquiries);
        setSortBy(lastSort);
        setLoading(false);

        if (selectionProperties.multiSelect) {
            const updatedEnqListIds = new Set<string>(updatedEnquiries.map(x => x.id.toString())); // did this for speed.
            let updatedList = updatedEnqListIds.intersection(new Set<string>(selectionProperties.selectedEnquiries));

            if (updatedList) {
                SetEnquiryGridSelectionProperties(currentState => {
                    return { ...currentState, selectedEnquiries: Array.from(updatedList) };
                });
            }
        }
    }, [enquiriesCache]);

    useEffect(() => {
        if (selectionProperties.multiSelect && selectionProperties.selectedEnquiries.length == 0) {
            SetEnquiryGridSelectionProperties(currentState => {
                currentState = JSON.parse(JSON.stringify(currentState));
                currentState.multiSelect = currentState.multiSelect;
                currentState.selectedEnquiries = [activeEnquiry.id.toString()];
                return currentState;
            });
        }
    }, [selectionProperties.multiSelect]);

    let preference: iPreference = getPreference();
    if (preference == null || preference == undefined) {
        preference = {
            interface: {
                MailboxMenuVisible: true,
                MyEnquiriesQueueStatusVisible: false,
                LeftPane: 50,
                RightPane: 50,
            },
        };
        savePrefence(preference);
    }
    
    const handleHeaderClick = (fieldName: string): any => {
        let updatedEnquires = [...allEnquiries];
        const lastTouched = getLastTouched();
        const activeList = lastTouched.MyEnquiries.EnquiryLists.find(l => l.Name == lastTouched.MyEnquiries.ActiveList)!;

        activeList.orderBy ??= { orderBy: null };

        if (activeList.orderBy.orderBy == fieldName) {
            activeList.orderBy = {
                orderBy: fieldName,
                desc: !activeList.orderBy.desc,
            };
        } else {
            activeList.orderBy = {
                orderBy: fieldName,
            };
        }

        const order = activeList.orderBy;
        if (order.orderBy) {
            if (order.orderBy == "owner") {
                updatedEnquires = sortOwner(updatedEnquires, order.desc);
            } else if (order.orderBy == "status") {
                updatedEnquires = sortStatus(updatedEnquires, order.desc);
            } else {
                updatedEnquires = sortByField(updatedEnquires, order.orderBy, order.desc);
            }

            saveActiveListEnquiries(updatedEnquires.map(x => x.id));
            setAllenquiries(updatedEnquires);
        }

        saveLastTouched(lastTouched);
        setSortBy(activeList.orderBy);
    };

    const multiSelectOnclick = (selectionProperties: gridSelectionProperties) => {
        SetEnquiryGridSelectionProperties(currentState => {
            currentState = JSON.parse(JSON.stringify(currentState));
            currentState.multiSelect = selectionProperties.multiSelect;
            currentState.selectedEnquiries = selectionProperties.selectedEnquiries;
            return currentState;
        });
    };

    return (
        <>
            {busyLoading == false && allEnquiries.length > 0 && (
                <>
                    <EnquiryGrid
                        data={allEnquiries}
                        theme="dataTable_default"
                        sortFunction={handleHeaderClick}
                        sortCol={sortBy}
                        selectedEnquiry={activeEnquiry.id}
                        columns={[
                            LastTouched.MyEnquiries.ActiveList == "Resolved" && selectionProperties.multiSelect == false
                                ? ""
                                : "verticalSPacer",
                            "priority",
                            "hasAlarm",
                            "hasUnworkedAttachments",
                            "enquiryReference",
                            "enquirerName",
                            "dateReceived",
                            "activeSlaCycle.timeout",
                            "status",
                            "subject",
                            LastTouched.MyEnquiries.ActiveList == "Resolved" ? "dateCompleted" : "",
                            "workItems",
                        ]}
                        multiSelect={selectionProperties.multiSelect}
                        selectedItems={selectionProperties.selectedEnquiries}
                        updateSelectedItems={multiSelectOnclick}
                    ></EnquiryGrid>
                </>
            )}
            {}
        </>
    );
}

export default MyEnquiries;
