import * as React from 'react';
import { MisEntry } from '../../../interfaces/ActionStartResponse';
import DropDownWithQuickFind from '../../Generic/Form/DropDownWithQuickFind';
import DateBox from '../../Generic/Form/Date';
import DateTimeBox from '../../Generic/Form/DateTime';
import TimeBox from '../../Generic/Form/Time';
import ConfirmDialog from '../../Generic/ConfirmDialog';
import TextBox from '../../Generic/Form/TextBox';
import { iEnquiry } from '../../../interfaces/iEnquiry';
import { BuildTemplatePreview, GetSmartFields, ParseAndFillTemplate, iParsedTemplate, SmartField, UpdateSmartTags, BuildTemplate } from '../../../Logic/Tags/TemplateBuilder';
import { getItem } from '../../../utilities/localStore/indexDBCalls';
import TemplatePreview from '../../HTMLEditor/templatePreview';
import { FormatDate, FormatFullDate, FormatTime } from '../../../Logic/DateTimeFormatters';


type templateProps = {
    Enquiry:iEnquiry;
    updateFunction: (body: string, subject: string, showEditor: boolean, feedbackDateTime?: string) => any,
    MISData:Array<MisEntry> ;
    ActionId:number;
    PopulateTemplateData: (subject:string, body:string)=> void;
    allTemplates: Array<{id: number, name: string }>;
    subject: string;
}


function Templates(props: templateProps) {
    const frameRef = React.useRef<HTMLIFrameElement>(null)
    const [sPreviewTempalte, setPreviewTemplate] = React.useState({body:"",subject:""});
    const [showTagsDialogue, setshowTagsDialogue] = React.useState(false);
    const [showTemplateChangeDialogue, setShowTemplateChangeDialogue] = React.useState(false);
    const [templateId, setTempalteId] = React.useState(props.allTemplates[0].id);
    let iParsedTemplateRef = React.useRef({} as iParsedTemplate);
    let smartTagsRef = React.useRef([] as SmartField[]);
    let feedbackDateTimeRef = React.useRef<string | undefined>();
  
    React.useEffect(()=>{
      (async ()=>{
        let mergedR = await ParseAndFillTemplate(templateId,props.Enquiry,props.MISData)
        let preview = await BuildTemplatePreview(mergedR);
        props.PopulateTemplateData(preview.subject, preview.body);
        iParsedTemplateRef.current = mergedR;
        let DOMParsing = new DOMParser()
        let parsed = DOMParsing.parseFromString(preview.body, "text/html");
        if(parsed){
            for(var i = 0 ; i<parsed.all.length;i++){
                if(parsed.all[i].attributes.length>0){
                    for(var x =0 ;x<parsed.all[i].attributes.length;x++){
                        if(parsed.all[i].attributes[x].name.toLowerCase()=="contenteditable"){
                            parsed.all[i].attributes[x].value = "false";
                        }
                    }
                }
            }
        }
        setPreviewTemplate({body:parsed.documentElement.outerHTML, subject:preview.subject});
      })();
       
    },[templateId]);
const updateTemplateWithSmartTags =async () =>{
    let aa = iParsedTemplateRef.current;
    UpdateSmartTags(aa,smartTagsRef.current);
    let builtTemplate = await BuildTemplate(aa);
    setshowTagsDialogue(false)

    if (feedbackDateTimeRef.current) {
        props.updateFunction(builtTemplate.body, builtTemplate.subject, true, feedbackDateTimeRef.current);
    } else {
        props.updateFunction(builtTemplate.body, builtTemplate.subject, true);
    }
    
}
    const updateSmartagValue = (value:string , id: string)=>{
        let smartTs = smartTagsRef.current;

        let smartTag = smartTs.find(x=>x.ID === id);
        if (!smartTag) {
            return;
        }
        
        if (smartTag.Type === "datetime") {
            smartTag.Value = FormatFullDate(value);
        } else if (smartTag.Type === "date") {
            smartTag.Value = FormatDate(value);
        } else if (smartTag.Type === "time") {
            smartTag.Value = FormatTime(value);
        }
        else {
            smartTag.Value = value;
        }

        if (smartTag.ID.toUpperCase() === "FEEDBACK_DATE_TIME") {
            feedbackDateTimeRef.current = value;
        }

        smartTagsRef.current = smartTs;
    }
    const createTagFields = async () => {
        const draft = await getItem("MailDrafts", props.Enquiry.id + "_" + props.ActionId);
        if (draft) {
            setShowTemplateChangeDialogue(true);
        }
        else {
            smartTagsRef.current = GetSmartFields(iParsedTemplateRef.current);

            if (smartTagsRef.current.length > 0) {
                setshowTagsDialogue(true);
            }
            else {
                let builtTemplate = await BuildTemplate(iParsedTemplateRef.current);
                props.updateFunction(builtTemplate.body, builtTemplate.subject, false);
            }
        }
    }

   function resizeIframe() {
        let ifrm = frameRef.current!;
        if (frameRef != null) {
            ifrm.style.height = ifrm.contentDocument?.body.clientHeight! + 50 + "px";
            if (ifrm.contentDocument?.body.scrollWidth! < 600) {
                ifrm.style.width = "100%"
            } else {
                ifrm.style.width = ifrm.contentDocument?.body.scrollWidth! + 50 + "px"
            }
        }
    }
    return (
        <>
            <div className='row align-items-center' style={{marginTop:"8px"}}>
                <div style={{gap:"4px"}} className='d-flex align-items-center justify-content-between'>
                    <DropDownWithQuickFind  value={templateId.toString()} returnFunc={(val)=>{setTempalteId(parseInt(val));}} key={"mis-field-1"} Valid={true} 
                    label='Select template' id="selectTemplate"
                        options={props.allTemplates.map((item, index) => {
                            return { value: item.id.toString(), text: item.name }
                        }).sort((a,b) => {
                            return a.text > b.text ? 1 :
                                a.text < b.text ? -1 : 0
                        }) }
                    />
                    <button style={{width: "100px"}} disabled={false} onClick={createTagFields} type='button' className='g-btn-action py-1'>Select</button>
                </div>
            </div>
            <div className="row" style={{marginBottom:"7px",marginTop:"6px"}}>
            <div style={{flex: "12 1 0"}} className='g-label'>Subject: &nbsp; {sPreviewTempalte.subject}</div>
        </div>
        <div className='row' style={{flex: "2 1 0"}}>
            <div className='col-xs-12'>
                <TemplatePreview initialValue={sPreviewTempalte.body} mailbox={props.Enquiry.mailbox}></TemplatePreview>
            </div>
        </div>

            {
                showTagsDialogue && <ConfirmDialog
                    title='Smart Tags'
                    onConfirm={() => {
                        updateTemplateWithSmartTags()
                    }}
                    onCancel={() => {
                        setshowTagsDialogue(false);
                    }}
                >

                    <div style={{ width: "600px" }}>
                        {
                           GetSmartFields(iParsedTemplateRef.current).map((item,index)=>{
                              switch(item.Type){
                                case "text": 
                                    {return <TextBox id={item.ID} label={item.Caption} value={item.Value} onChange={(val)=>{updateSmartagValue(val,item.ID)}} maxLength={45} firstCol={5} key={item.ID}/>}
                                    break;
                                    case "numeric":
                                        {return  <TextBox id={item.ID} label={item.Caption} value={item.Value} onChange={(val)=>{updateSmartagValue(val,item.ID)}} maxLength={45} firstCol={5} key={item.ID}/>}
                                    break;
                                case "date":
                                        {return  <DateBox id={item.ID} label={item.Caption} value={item.Value} initializeDefault={true} onChange={(val)=>{updateSmartagValue(val,item.ID)}} firstCol={5} key={item.ID}/>}
                                    break;
                                case "datetime":
                                        {return  <DateTimeBox id={item.ID} label={item.Caption} value={item.Value} initializeDefault={true} onChange={(val)=>{updateSmartagValue(val,item.ID)}} firstCol={5} key={item.ID}/>}
                                    break;
                                case "time":
                                        {return  <TimeBox id={item.ID} label={item.Caption} value={item.Value} initializeDefault={true} onChange={(val)=>{updateSmartagValue(val,item.ID)}} firstCol={5} key={item.ID}/>}
                                    break;
                                case "currency":
                                        {return  <TextBox id={item.ID} label={item.Caption} value={item.Value} onChange={(val)=>{updateSmartagValue(val,item.ID)}} maxLength={15} firstCol={5} key={item.ID}/>}
                                    break;
                                    case "dropdown":
                                        {return  <DropDownWithQuickFind  label={item.Caption} value={item.Value} returnFunc={(val)=>{ updateSmartagValue(val,item.ID)}}   firstCol={5} key={item.ID} Valid={true} id={item.ID}
                                        options={item.Options?.map((x)=>{
                                            return {value:x , text:x}
                                        })}></DropDownWithQuickFind>}
                                    break;
                                   
                              }
                           })
                        }
                       
                         
                    </div>
                </ConfirmDialog>
            }
            {
                showTemplateChangeDialogue && <ConfirmDialog
                    title='Warning'
                    onConfirm={async () => {
                        smartTagsRef.current = GetSmartFields(iParsedTemplateRef.current);

                        if (smartTagsRef.current.length > 0) {
                            setshowTagsDialogue(true);
                        }
                        else {
                            let builtTemplate = await BuildTemplate(iParsedTemplateRef.current);
                            props.updateFunction(builtTemplate.body, builtTemplate.subject, false);
                        }
                        setShowTemplateChangeDialogue(false)
                    }}
                    onCancel={() => {
                        setShowTemplateChangeDialogue(false);
                    }}
                >
                    <>Unsaved changes will be lost. Are you sure that you wish to change the template?</>
                </ConfirmDialog>
            }
        </>
 
    );
}

export default Templates;
