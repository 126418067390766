import './email.scss';
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from '../../state/store'
import Preview from './preview';
import Enquiry from '../Enquiries/enquiry/enquiry'
import { GetWorkflowFromStorage, Wizard } from '../../state/workflowSlice';
import { iEnquiry } from '../../interfaces/iEnquiry';
import { iGenericComm, ToGenericComm } from '../../interfaces/iGenericComm';
import { useState } from 'react';
import React from 'react';
import { getLastCommunication, setLastCommunication } from '../../Logic/LastTouched/comm';
import { getLocalValue } from '../../utilities/localStore/calls';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from '../../error-boundary/errorFallback';
import { defaultReset } from '../../error-boundary/logerror';
import { iLocalMessage } from '../../interfaces/emailModel';
import { updateCommunicationCache } from '../../state/communicationsCache';
import { GetEnquiryCommunications } from '../../Logic/EmsAPI/Enquiries';

type switchProps = {
    FullWidth: boolean,
    enquiry: iEnquiry,
    interface?: string,
    hasActivateWiz: boolean
}

function Switch(props: switchProps) {

    const dispatch = useAppDispatch()
    const [commToView, setcommToView] = useState<iGenericComm | undefined>(undefined);
    const communicationsCache = useSelector((state: RootState) => state.CommunicationsCache);
    const communications = communicationsCache.data.find(x=>x.enquiryId == props.enquiry.id)?.communications
    
    function changeCommToView(commId: number) {
        const comm = communications?.find(x => x.id == commId);
        if(!comm) return;
 
        setcommToView(ToGenericComm(comm));
        setLastCommunication(props.enquiry.id, commId);
    }

    const wizard = GetWorkflowFromStorage(props.enquiry?.id);

    if (wizard != undefined) {
        const localAttachments = getLocalValue<iLocalMessage[]>("localAttachments") ?? [];
        let localMessage = localAttachments?.find(x => x.communicationId == wizard.lastCommunication?.id)

        if (localMessage != undefined) {
            let comm = ToGenericComm(wizard?.lastCommunication!);
            setcommToView(comm);
        }
    }
 
    React.useEffect(() => {
       const checkLastComm = async () =>{ 
        let commsForEnquiry = communicationsCache.data.find(x=>x.enquiryId == props.enquiry.id); 
        // first check if there are comms...
        if(commsForEnquiry==undefined || (commsForEnquiry && commsForEnquiry.cachedAt!=props.enquiry.commUpdatedAt) ){
            let commResponse = await GetEnquiryCommunications(props.enquiry.id);
                           if (!commResponse.isOk) {
                                throw new Error(`${commResponse.status}  ${commResponse.statusText} attempted to get comms on switch`); // the error boundary will then trap this error
                            }
                            if (!commResponse.data) {
                                throw new Error(`${commResponse.statusText} no data returned from server when getting comms on switch`); // the error boundary will then trap this error
                            }
            dispatch(updateCommunicationCache({
                enquiryId: props.enquiry.id,
                communications: commResponse.data,
                cachedAt:props.enquiry.commUpdatedAt
            }));
            commsForEnquiry={
                enquiryId: props.enquiry.id,
                communications: commResponse.data,
                cachedAt:props.enquiry.commUpdatedAt
            }
        }
        
        // get the last touched comms that was clicked by user...
        let lastCommId = getLastCommunication(props.enquiry.id);
        let  lastCommunication = commsForEnquiry?.communications.find(c => c.id === lastCommId);
        if (lastCommunication) {
            setcommToView(ToGenericComm(lastCommunication));
        }else{
            if ((commsForEnquiry?.communications.find(x => x.id == commToView?.id) == undefined && (commsForEnquiry && commsForEnquiry?.communications?.length > 0))) {
                if (lastCommId == 0) {
                    if (commsForEnquiry?.communications?.length) {
                        lastCommId = commsForEnquiry?.communications[0].id
                        lastCommunication = commsForEnquiry?.communications[0]
                        setcommToView(ToGenericComm(lastCommunication))
                    }
                }
            }}
        }
        checkLastComm()
    }, [props.enquiry.id,props.enquiry.commUpdatedAt])

    return (
        <>
            {
                (Object.keys(props.enquiry).length > 0) &&
                <div className='enquiry-history'>

                    <div className='enquiry-history--enquiry'>
                        <ErrorBoundary
                            FallbackComponent={ErrorFallback}
                            onReset={defaultReset} 
                        >
                         { communications &&  <Enquiry
                                key={"key_" + props.enquiry?.id}
                                e={props.enquiry}
                                interface={props.interface}
                                commOnclick={(e: number) => { changeCommToView(e) }}
                                activeCommId={commToView?.id ?? 0}
                                hasActivateWiz={props.hasActivateWiz}
                                communications={communications}
                            />
                            }
                        </ErrorBoundary>
                    </div>

                    {
                        ((communications && communications?.length>0)  && commToView) && <Preview
                            comm={commToView}
                            FullWidth={props.FullWidth}
                            enquirerName={props.enquiry?.enquirerName}
                            activeEnquiryId={props.enquiry.id}
                        />
                    }
                </div>
            }
        </>
    )
}

export default Switch;