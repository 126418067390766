import { useContext, useEffect, useState } from "react";
import { EStepKey } from "../../../Logic/Wokflow/stepLogic";
import { WizardStepProps } from "../stepType";
import Step from '../template'
import { getEnquiryById } from "../../../interfaces/enquiryList";
import { WizardContext } from "../../../contexts/wizard-context";
import { iCommunication } from "../../../interfaces/iEnquiry";
import Preview from "../../Email/preview";
import { ToGenericComm } from "../../../interfaces/iGenericComm";
import { BsExclamationLg, BsFileEarmark, BsPaperclip } from "react-icons/bs";
import { startVDrag } from "../../../Logic/Resize/vertical";
import Communication from "../../Enquiries/communication/communication";

export function SelectEmail(props: WizardStepProps) {

    const [inbounds, setInbounds] = useState<iCommunication[]>([]);
    const { wizard, setWizard, onNext, onBack } = useContext(WizardContext);

    const commPreview: iCommunication | undefined = inbounds.find(comm => comm.id === wizard.workflow.data.NewEnquiryCommID);

    useEffect(() => {
        const inboundComms: iCommunication[] = props.Communications?
            props.Communications
            .filter(comm => comm.type === "Email" && comm.email?.incoming === true)
            ?? [] : [];
        setInbounds(inboundComms);
        if (inboundComms.length > 0) {
            if (wizard.workflow.data.NewEnquiryCommID === undefined || inboundComms.find(ibc => ibc.id === wizard.workflow.data.NewEnquiryCommID) === undefined) {
                setSelectedComm(inboundComms[0].id);
            }
        }

    }, [props.Enquiry.id]);

    const setSelectedComm = (commId: number | undefined) => {
        setWizard(wiz => (
            {
                ...wiz,
                workflow: {
                    ...wiz.workflow,
                    data: {
                        ...wiz.workflow.data,
                        NewEnquiryCommID: commId
                    }
                }
            }
        ));
    }

    const backFunc = () => {
        setSelectedComm(undefined);
        onBack();
    }

    const nextFunc = () => {
        if (wizard.workflow.data.NewEnquiryCommID === undefined || wizard.workflow.data.NewEnquiryCommID === 0) {
            alert("Please select communication for the new enquiry.");
            return;
        }
        onNext();
    }

    const startVDragging = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        startVDrag(e, inbounds.length, props.Enquiry.id,"selectEmail");
    }

    return (
        <Step
            step={EStepKey.SELECT_EMAIL}
            title={"Select email to make new enquiry"}
            Enquiry={props.Enquiry}
            Popped={props.Popped}
            backF={backFunc}
            nextF={nextFunc}
        >
            {inbounds.length > 0 && <table className="comm-list" style={{ marginLeft: props.FullWidth ? "4px" : "5px" }} cellPadding={0} cellSpacing={0}>
                <thead className={inbounds.length > 4 ? "tHeaddWithScroll comm-list--header" : "tHeadd comm-list--header"}>
                    <tr style={{ height: "100%", width: "100%" }} className="noScrollBar">
                        <th style={{ width: "15px", textAlign: "center", paddingLeft: "4px" }}><i style={{ width: "100%" }}><BsExclamationLg style={{ height: "20px" }} /></i></th>
                        <th style={{ width: "11px", textAlign: "center", padding: "0px", margin: "0px" }}><i style={{ height: "20px" }}><BsFileEarmark style={{ height: "20px" }} /></i></th>
                        <th style={{ width: "15px", textAlign: "center", height: "20px", paddingRight: "4px" }}><i style={{ width: "100%" }}><BsPaperclip style={{ height: "20px" }} /></i></th>

                        <td className="limit-text" style={{ width: "12%" }}>Received</td>
                        <td className="limit-text" style={{ width: "20%" }}>From</td>
                        <td className="limit-text" style={{ width: "20%" }}>To</td>
                        <td className="limit-text" style={{ width: "35%" }}>Subject</td>
                        <td className="limit-text" style={{ width: "7%", paddingRight: "10px", marginRight: "5px" }}>Size</td>
                    </tr>
                </thead>
                <tbody className='comm-list--list' data-testid='enquiry-communication-list1' id={"commListHistory" + props.Enquiry.id+"selectEmail"}>
                    {inbounds.map(item => <Communication
                        id={item.id}
                        type={item.type}
                        dateStamp={item.dateStamp}
                        email={item.email}
                        comm={ToGenericComm(item)}
                        isCompletedEnquiry={false}
                        key={"com_" + item.id}
                        onClick={(commId: number) => setSelectedComm(commId)}
                        activeCommId={wizard.workflow.data.NewEnquiryCommID ?? 0}
                        enquiryId={props.Enquiry.id}
                    />)}
                </tbody>
                <tfoot>
                    <tr style={{ width: "100%" }}>
                        <td colSpan={8} className="details comm-expand-btn">Items: {inbounds.length}</td>
                    </tr>
                    <tr style={{ height: "3px" }}>
                        <td id={props.Enquiry.id+"selectEmail"} colSpan={8} className={inbounds.length > 4 ? "commResizeWait" : "commResize"} style={{ height: "3px" }} onMouseDown={(event) => startVDragging(event)}></td>
                    </tr>
                </tfoot>
            </table>}

            {commPreview !== undefined && <Preview
                comm={ToGenericComm(commPreview)}
                FullWidth={props.FullWidth}
                enquirerName={""}
                activeEnquiryId={props.Enquiry.id}
                uniqVal="selectEmail"
            />}
        </Step>
    )
}