import { useContext, useEffect, useRef, useState } from 'react';
import { BsChevronDown, BsSearch } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { CreateNoteContext } from '../../../contexts/create-note.context';
import useOutsideClick from '../../../hooks/useOutsideClick';
import { iTenant } from '../../../interfaces/auth';
import { GetMailboxPermissions } from '../../../interfaces/Permissions';
import { getActiveTenantName } from '../../../Logic/Auth/TokenManager';
import { ActivateWizardModule, saveNewEnquiryId } from '../../../Logic/Enquiries/activateWizard';
import { LoadUnresolved } from '../../../Logic/Enquiries/Load';
import { getLastTouched, saveLastTouched } from '../../../Logic/LastTouched/misc';
import { setShowEnquiry, setShowWizard } from '../../../state/restrictedViewSlice';
import { setEnquiriesVisibility, setUserPreferancesVisibility } from '../../../state/siteSlice';
import { RootState, useAppDispatch } from '../../../state/store';
import { setTabVisibility, switchFirstTab } from '../../../state/tabRow';
import { Wizard } from '../../../state/workflowSlice';
import { callApi, iRequest } from '../../../utilities/apiCall';
import { getSessionValue } from '../../../utilities/localStore/calls';
import { resetSort } from '../../../utilities/sort';
import { default as Confirm, default as ConfirmDialog } from '../../Generic/ConfirmDialog';
import RadioButtonRow from '../../Generic/RadioButtonRow';
import AvancedSearchDialog from './AdvancedSearchDialog';
import './topBar.scss';
import { CreateEnquiry } from '../../../Logic/EmsAPI/Enquiries';
import { updateEnquiriesCache } from '../../../state/enquiriesCache';

function Topbar(props: any) {
    const dispatch = useAppDispatch()
    const UserInfo = useSelector((state: RootState) => state.UserInformation);
    const activeEnquiry = useSelector((state: RootState) => state.activeEnquiry);
    const newRef = useRef<HTMLElement>(null);
    const profileRef = useRef<HTMLButtonElement>(null);
    const permissions = useSelector((state: RootState) => state.permissions);
    const [displayAdvancedSearch, setDisplayAdvancedSearch] = useState(false);
    const [showConfirm, setshowConfirm] = useState(false);
    const [showCreateEnquiry, setshowCreateEnquiry] = useState(false);
    const [newMenuShown, setNewMenuShown] = useState(false);
    const [profileMenuShown, setProfileMenuShown] = useState(false);
    const [selectedMailbox, setselectedMailbox] = useState(0);

    const [hasMultiTenantAccess, setHasMultiTenantAccess] = useState(false);
    const [showSwitchTenantsConfirm, setShowSwitchTenantsConfirm] = useState(false);

    const createNote = useContext(CreateNoteContext);

    useEffect(() => {
        const tenant = getSessionValue<iTenant>("tenant");
        if (tenant !== null && tenant.accessToMultipleTenants ) {
            setHasMultiTenantAccess(true);
        }
    }, []);

    useOutsideClick(newRef, () => setNewMenuShown(false))
    useOutsideClick(profileRef, () => setProfileMenuShown(false))

    function getPopEnquiry() {
        (document.getElementById("loader") as HTMLElement).style.display = "block";
        let request: iRequest = {
            url: "Enquiry/Pop",
            method: "GET",
            headers: [],
            body: JSON.stringify({ "UserId": UserInfo.userID }),
            responseType: "json"
        }
        callApi(request).then(async (resp) => {
            if (resp == "No Content") {
                alert("There are no more unassigned enquiries waiting to be processed");
                (document.getElementById("loader") as HTMLElement).style.display = "none";
                return false;
            }

            else if (typeof (resp.status) == "number" && resp.status == 500) {
                alert("An error occured - " + resp.detail);
                (document.getElementById("loader") as HTMLElement).style.display = "none";
                return false;
            }
            try {
                const lastTouched = getLastTouched();
                const unresolvedList = lastTouched.MyEnquiries.EnquiryLists.find(x => x.Name == "Unresolved")!;
                lastTouched.MyEnquiries.ActiveList = "Unresolved";
                lastTouched.lastActiveListType = "Unresolved";
                unresolvedList.EnquiryId = resp.id;
                saveLastTouched(lastTouched);

                await LoadUnresolved();

                dispatch(setShowEnquiry(false));
                dispatch(setShowWizard(false));
                dispatch(switchFirstTab("My Enquiries"));
                dispatch(setEnquiriesVisibility());
            }
            catch (err) {
                alert(err);
            }


        }).catch((err) => {

            if (err.indexOf("User has unworked enquiries") > 0) {
                setshowConfirm(true);
            }
            else { alert("an error has occured") }
            (document.getElementById("loader") as HTMLElement).style.display = "none";

        });
    }

    const createEnquiry = async () => {
        if (!UserInfo.mailboxes.find(x => x.id === selectedMailbox)) {
            return;
        }

        const response = await CreateEnquiry(selectedMailbox);
        if (!response.isOk || !response.data) {
            throw new Error("Failed to create enquiry.");
        }

        const newEnquiry = response.data;
        dispatch(updateEnquiriesCache([newEnquiry]));

        saveNewEnquiryId(newEnquiry.id.toString())
        dispatch(setTabVisibility(0));
        setshowCreateEnquiry(false);

        new ActivateWizardModule().ActivateWizard(newEnquiry.id);
    }

    const onSwitchTenant = () => {
        const wizards = getSessionValue<Wizard[]>("Workflows");
        if (wizards !== null && wizards.length > 0) {
            setShowSwitchTenantsConfirm(true);
            return;
        }
       // dispatch(setActiveEnquiry(0));
        sessionStorage.clear();
        window.location.pathname = "/auth/callback";
    };

    return (
        <>

            <div className="topBar topBarTheme">
                <div className="left-area">
                    <nav ref={newRef} className={"g-dropdown-nav" + (newMenuShown ? "-selected" : "")} >
                        <button
                            className="g-nav-button"
                            onClick={() => setNewMenuShown(s => !s)}
                        ><div className='limit-text'>New</div> <BsChevronDown style={{ fontSize: "10px", marginLeft: "10px" }}></BsChevronDown></button>
                        <ul
                        className="g-dropdown-nav-list"
                        onMouseLeave={()=>{setNewMenuShown(false) }}
                        >
                            <li ><button onClick={(e) => {   permissions.AnyMailbox.CreateEnquiry && setshowCreateEnquiry(true) }}  tabIndex={0} 
                            className={!permissions.AnyMailbox.CreateEnquiry ? "sg-dropdownmenu-disabled" : ""} disabled={!permissions.AnyMailbox.CreateEnquiry ? true : false}>
                                Create&nbsp;Enquiry</button></li>
                            <li><button onClick={() => { createNote(activeEnquiry.id) }}
                                className={`sg-dropdownmenu${!activeEnquiry.id || activeEnquiry.id <= 0 ? "-disabled" : ""}`}>Create&nbsp;Note</button></li>
                        </ul>
                    </nav>
                    {permissions.AnyMailbox.PopEnquiry &&
                        <nav className="g-dropdown-nav" >
                            <button className="g-nav-button" onClick={() => {
                                resetSort();
                                getPopEnquiry();
                            }} >
                                Pop
                            </button>
                        </nav>
                    }
                    <nav className="g-dropdown-nav" >
                        
                    </nav>
                    {permissions.Tenant.Search && <div className="search-area">
                        <div className="search-icon-container"><BsSearch className="search-icon" /></div>
                        <input type="text" value="Search Enquiries" readOnly={true} onClick={() => setDisplayAdvancedSearch(true)} />
                        {displayAdvancedSearch && <AvancedSearchDialog onClose={() => setDisplayAdvancedSearch(false)} fullWidth={props.FullWidth} />}
                    </div>}
                </div>
                <div className="right-area">
                    <label className='limit-text'>{getActiveTenantName(false)}&nbsp;&nbsp;</label>
                    <nav ref={profileRef} className={"g-dropdown-nav" + (profileMenuShown ? "-selected" : "") + " menu-right"} > 
                        <button
                            className="g-nav-button"
                            onClick={()=>{setProfileMenuShown(s => !s) }}
                        ><div className='limit-text'>{UserInfo.firstname + ' ' + UserInfo.lastname}</div> <BsChevronDown style={{ marginLeft: "10px" }}></BsChevronDown>
                        </button>
                        <ul onMouseLeave={() => setProfileMenuShown(false)} className="g-dropdown-nav-list">
                            {UserInfo.jobTitle !== undefined && <li><label>{UserInfo.jobTitle.replace(/ /g, "\u00a0")}</label></li>}
                            <li><label>{UserInfo.serviceCentre.replace(/ /g, "\u00a0")}</label></li>
                            {hasMultiTenantAccess && <li ><button tabIndex={0} onClick={() => onSwitchTenant()} onBlur={() => { setProfileMenuShown(false) }}>Switch Tenant</button></li>}
                            <li><button onClick={()=>{dispatch(setUserPreferancesVisibility())}} >Preferences</button></li>
                            <li><button className="sg-dropdownmenu-disabled" disabled={true}>My Profile</button></li>
                            <li><button className="sg-dropdownmenu-disabled" disabled={true}>Help</button></li>
                        </ul>
                 
                    </nav>
                </div>
            </div>
            {showConfirm &&
                <ConfirmDialog
                    title=''
                    onConfirm={() => {
                        setshowConfirm(false);
                    }}
                    onCancel={() => {
                        setshowConfirm(false);
                    }}
                >
                    <p>There already exists an unprocessed enquiry in your queue. Please action this enquiry before popping more work.</p>
                    <p> </p>
                </ConfirmDialog>
            }



            {
                showCreateEnquiry &&
                <Confirm
                    onCancel={() => { setshowCreateEnquiry(false) }}
                    onConfirm={() => { createEnquiry() }}
                    title='Link New Enquiry to Mailbox'
                >
                    <span></span>
                    <p>Select one mailbox to attach the new enquiry to from the mailboxes available to you.</p>
                    <div className='linkMailBoxSelector'>
                        {
                            UserInfo.mailboxes
                                .filter(x => GetMailboxPermissions(permissions, x.id).CreateEnquiry)
                                .map((item) => {
                                    return <RadioButtonRow
                                        key={item.id}
                                        disabled={false}
                                        comboKey={item.id.toString()}
                                        onClick={() => { setselectedMailbox(item.id) }}
                                        label={item.name}
                                        value={item.id}
                                        selectedValue={selectedMailbox} />
                                })
                        }

                    </div>
                </Confirm>
            }

            {showSwitchTenantsConfirm &&
            <Confirm title={"Warning"} onConfirm={async () => setShowSwitchTenantsConfirm(false)} messageType={"warn"}>
                <label>
                    Please complete or cancel any open wizard before switching tenants.
                </label>
            </Confirm>
            }
        </>
    )
}

export default Topbar;