import { iEmail, iLocalMessageAttachment, iPart } from "../../interfaces/emailModel"
import { Delete, Post, callApi, iRequest, uploadToS3Bucket, uploadToS3BucketXHR } from "../../utilities/apiCall";
import { zipFile, zipText } from "../../utilities/gZip";
import { addItem, deleteItem, getItem } from "../../utilities/localStore/indexDBCalls";
import { getMimeType } from "../../utilities/mimeTypes";
import { getDraftId, saveDraftToIndexedDb } from "./draft";
import worker_script from '../../workerProcesses/sendInlineAttachmentToS3';
import { Wizard } from "../../state/workflowSlice";
import { showHideLoader } from "../../utilities/loading";
import { iAttachmentEvent } from "../../interfaces/iAttachmentEvent";
import { getLocalValue } from "../../utilities/localStore/calls";
import { iAuth } from "../../interfaces/auth";
 

export const inlineParts = async (bblob: Blob, MailDraftId: number, dataURL: string, fileName: string, mimeContentID: string) => {
    var file = new File([bblob], fileName);
    const f = await file.arrayBuffer();
    const res = await zipFile(f, file.type);
    const part: iPart = {
        ContentLength: f.byteLength,
        CompressedContentLength: res.length,
        MimeType: bblob.type ? bblob.type : getMimeType(fileName),
        Type: 2,
        Name: fileName,
        MimeContentID: mimeContentID
    };

    const response = await Post(`Draft/${MailDraftId}/Part`, part);

    let inlineA = {
        id: response.id,
        file: "object File",
        name: fileName,
        mimeType: bblob.type,
        size: res.byteLength,
        mimeContentID: part.MimeContentID,
        dataUrl: dataURL
    };
    
    const S3Result = await SendInlineAttachToS3(res, response.uploadUrl)
    if(S3Result){
        await addItem("Blobs", { "id": response.id, "data": res }); // insert the blob into indexDB
        await completePart(response.id);
        return inlineA;
    }else{
        alert("The picture could not be embedded, please remove it and try again")
        await deleteInlineAttachments(MailDraftId,response.id);
        return "";
    }
}

export const deleteInlineAttachments = async (mailDraftId: number, draftPartId: string): Promise<any> => {
    return new Promise((resolve, reject) => {
        Delete(`Draft/${mailDraftId}/Part/${draftPartId}`).then((response) => {
            resolve("success")
        })
            .catch((err) => {
                reject(err);
            });
    });
}


export const prepareBody = async (enquiryId: number, ed: iEmail): Promise<string> => {
    let maildraftId = await getDraftId(1, ed); // this will update the draft on the backend;
    if (ed.inlineAttachments!.length > 0) {
        let DOMParsing = new DOMParser()
        let parsed = DOMParsing.parseFromString(ed.body, "text/html");
        let inlineimgages = parsed.querySelectorAll("img")!;
        // this is needed because if the attachemtnt is not in the Body then it must be rem
        for (var i = 0; i < ed.inlineAttachments!.length; i++) {

            if (ed.body.indexOf(ed.inlineAttachments![i].mimeContentID!) > 0) {
                //replace the img URL with cid MimeContentId
                for (var ii = 0; ii < inlineimgages.length; ii++) {
                    if (inlineimgages[ii].title == ed.inlineAttachments![i].mimeContentID) {
                        ed.body = ed.body.replace(inlineimgages[ii].src, ("cid:" + ed.inlineAttachments![i].mimeContentID));
                    }
                }
            }
            else {
                await deleteItem("Blobs", ed.inlineAttachments![i].id) //delete the attachemtn from the local blob in indexDb
                await deleteInlineAttachments(maildraftId, ed.inlineAttachments![i].id).catch((err) => { console.log(err) });
            }
        }
        return (ed.body);
    }
    else {
        return (ed.body)
    }



}

export const completePart = async (draftPartId: number) => {
    try {
        await Post("Draft/PartComplete", { ID: draftPartId });
        return true;
    }
    catch (error) {
        console.log(error);
        return error;
    }

}

export async function UploadMailBody(enquiryId: number, mailDraftId: number, email: iEmail): Promise<any> {

    if (email.inlineAttachments!.length > 0) {
        email.body = await prepareBody(enquiryId, email);
    }

    //remove the contentediable parts before sending the email to prevent legacy bug;
    let DOMParsing = new DOMParser()
    let parsed = DOMParsing.parseFromString(email.body, "text/html");
    parsed.querySelectorAll("div[contenteditable]")!.forEach(function (el) {
        el.removeAttribute("contenteditable");
    });

    let styleNode = parsed.querySelectorAll('style');
    let styles: Map<string, string> = new Map();
    styleNode[0].innerText.split('}', 1000).map((item, index) => {
        return styles.set(item.length > 0 ? item.substring(0, item.indexOf("{")).replaceAll(" ", "").toLowerCase() : "", item.length > 0 ? item.substring(item.indexOf("{") + 1).replaceAll(" ", "") : "");
    });
    Array.from(styles.keys()).forEach(x => {
        if (x != "") {
            parsed.querySelectorAll(x)!.forEach(function (el) {
                if (el.attributes.length == 0) {
                    const styleNode = document.createAttribute("class");
                    styleNode.value = "EmsNormal";
                    el.attributes.setNamedItem(styleNode);
                }
            });
        }
    });
    email.body = (parsed.documentElement.outerHTML);

    // remove the invisible character that is causing an underscore to be shown in legacy
    email.body = email.body.replace("‎", "");
    //remove the tinymce formatiing for tables and other classes that tinymce inserts
    email.body = email.body.replaceAll('class="mce-content-body "',""); 
    email.body = email.body.replaceAll('mce-resizehandle',""); 
    email.body = email.body.replaceAll('ephox-snooker-resizer-rows',""); 
    email.body = email.body.replaceAll('ephox-snooker-resizer-bar',""); 
    email.body = email.body.replaceAll('ephox-snooker-resizer-cols',"");
    const compressedBody = await zipText(new TextEncoder().encode(email.body));
    const part: iPart = {
        ContentLength: email.body.length,
        CompressedContentLength: compressedBody.length,
        MimeType: "text/html",
        Type: 1,
        Name: "s"
    }

    return await doMailDraftParts(mailDraftId, part, compressedBody);
}

export const doMailDraftParts = async (mailDraftId: number, part: iPart, file: string): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        Post(`Draft/${mailDraftId}/Part`, part).then((responsePart) => {
            uploadToS3Bucket(responsePart.uploadUrl, file).then(() => {
                completePart(responsePart.id).then(() => {
                    resolve({ mailDraftId: mailDraftId, partId: responsePart.id });
                }).catch((error) => {
                    reject(error);
                })
            })
        });

    });

}


export const deletePart = async (draftPartId: number, emailComposer: iEmail, wizard?: Wizard): Promise<any> => {
    let ed:iEmail = JSON.parse(JSON.stringify(emailComposer));
    showHideLoader("flex");
    return new Promise(async (resolve, reject) => {
        getDraftId(3, ed).then(async (mailDraftId: number) => {
            await Delete(`Draft/${mailDraftId}/Part/${draftPartId}`).then((response) => {
                showHideLoader("none");
                ed.attachments = ed.attachments.filter((part) => { return part.id != draftPartId.toString() })
                saveDraftToIndexedDb(ed, false).then(() => {
                    resolve(ed);
                });

            }).catch((e) => {
                ed.attachments = ed.attachments.filter(att => att.id !== draftPartId + "")
                saveDraftToIndexedDb(ed, false).then(() => {
                    resolve(ed);
                });

            })

        });
    });
}

export async function saveFile(file: File, emailComposer: iEmail): Promise<iEmail> {
    return new Promise(async (resolve, reject) => {
        let ed = emailComposer;
        await file.arrayBuffer().then((f) => {
            zipFile(f, file.type).then(async (res) => {
                const part: iPart = {
                    ContentLength: f.byteLength,
                    CompressedContentLength: res.length,
                    MimeType: file.type ? file.type : getMimeType(file.name),
                    Type: 3,
                    Name: file.name
                }
                await doMailDraftParts(ed.maildraftId, part, res).then(async (parts) => {
                    let a = {
                        id: parts.partId,
                        file: f.toString(),
                        name: file.name,
                        mimeType: file.type,
                        size: f.byteLength
                    };
                    ed.attachments.push(a);
                    await addItem("MailDrafts", ed).then((dbCall) => {
                        (document.getElementById("hiddenFile") as HTMLInputElement).value = "";
                        resolve(ed);
                    });

                })
            });
        });
    });
}

export async function insertAttachmentIntoIndexedDB(file: File, emailComposer: iEmail,): Promise<iEmail> {
    return new Promise(async (resolve, reject) => {
        let ed = emailComposer;
        let fileArray = await  file.arrayBuffer();
        let zFile = await zipFile(fileArray, file.type)
        const part: iPart = {
            ContentLength: fileArray.byteLength,
            CompressedContentLength: zFile.length,
            MimeType: file.type ? file.type : getMimeType(file.name),
            Type: 3,
            Name: file.name
        }
    
        let responsePart =  await Post(`Draft/${ed.maildraftId}/Part`, part);
        await addItem( "Blobs", { "id": responsePart.id, "data": zFile })

        let a:iLocalMessageAttachment = {
            id: responsePart.id,
            file: fileArray.toString(),
            name: file.name,
            mimeType: file.type,
            size: (zFile.byteLength /1000), // bytelength this value is in bytes 
            status:"pending",
            uploadUrl:responsePart.uploadUrl
        };
        ed.attachments.push(a);
        resolve(ed);
    });
}

export const uploadFileViaXHR = async (blob:any, id:string, uploadUrl:string, emailComposer: iEmail)=>{
    if(blob==null){
        let item = await getItem("Blobs", id)
        blob = item.data;
    }
    var xhr = new XMLHttpRequest();
    xhr.upload.addEventListener("progress",     (e)=>{    
        let aEvent:iAttachmentEvent = {} as iAttachmentEvent;
        aEvent.process = "Progress";
        aEvent.loaded = e.loaded;
        aEvent.total = e.total
        aEvent.attachmentId= id;
        var event = new CustomEvent("attach_Event_"+id, {"detail": aEvent });
        document.dispatchEvent(event);
       }    , false);
    xhr.addEventListener("abort", async (e:any)=>{
        const ec:iEmail = await getItem("MailDrafts", emailComposer.uId);
        await deletePart(parseInt(id), emailComposer);
        let aEvent:iAttachmentEvent = {} as iAttachmentEvent;
        aEvent.process = "Abort";
        aEvent.attachmentId= id;
        var event = new CustomEvent("attach_Event_"+id, {"detail": aEvent });
        document.dispatchEvent(event);
    }
    , false);
    xhr.addEventListener("error", async (e:any)=>{alert(e);
        const ec:iEmail = await getItem("MailDrafts", emailComposer.uId);
        ec.attachments.find(x=>x.id == id)!.status! = "error";
        await saveDraftToIndexedDb(ec, false);
        let aEvent:iAttachmentEvent = {} as iAttachmentEvent;
        aEvent.process = "Error";
        aEvent.attachmentId= id;
        window.XhrRequests = window.XhrRequests.filter(x=>x.id!=id);
        var event = new CustomEvent("attach_Event_"+id, {"detail": aEvent });
        document.dispatchEvent(event);
    }, 
    );
    xhr.addEventListener("load", async (e:any)=>{
        const ec:iEmail = await getItem("MailDrafts", emailComposer.uId);
        ec.attachments.find(x=>x.id == id)!.status! = "uploaded";
        saveDraftToIndexedDb(ec, false);
        await completePart(parseInt(id));
        let aEvent:iAttachmentEvent = {} as iAttachmentEvent;
        aEvent.process = "Uploaded";
        aEvent.attachmentId= id;
        var event = new CustomEvent("attach_Event_"+id, {"detail": aEvent });
        window.setTimeout(()=>{   document.dispatchEvent(event);}, 200); // this is to make sure the attachment progress component has a chance to render
        

    }, false);
    window.XhrRequests.push({id:id, req:xhr}); 
    xhr.open("PUT", uploadUrl);
    xhr.setRequestHeader("Content-Type", "multipart/form-data");
    xhr.send(blob);
}


async function SendInlineAttachToS3(data:any, S3URL: string)
{
      const hHeaders = new Headers();
      hHeaders.append("Content-Type", "multipart/form-data");
      var fetchOptions:any = {}
      fetchOptions.method = "PUT";
      fetchOptions.headers = hHeaders;
      fetchOptions.body = data;
      let resp = await fetch(S3URL, fetchOptions);
      if(resp.ok){
        return true;
      }
      else{
        return false;
      }
}