import { AdvancedSearchResults } from "../../interfaces/AdvancedSearch/AdvancedSearchResults";
import { iEnquiry } from "../../interfaces/iEnquiry";
import store from "../../state/store";

export interface EnquiryCache {
    CachedAt: Date,
    Enquiry: iEnquiry
}

export interface LastTouchedComm {
    Id: number,
    ScrollPosition: number
}

// session storage
export function getSessionValue<T>(key: string, defaultValue: T | null = null): T | null {
    if (typeof window === undefined || typeof window === "undefined")
        return defaultValue;
    try {
        const item: string | null = window.sessionStorage.getItem(key);
        const tItem: T | null = JSON.parse(item ?? "null");
        if (tItem === null)
            return defaultValue;
        return tItem;
    } catch (error) {
        return defaultValue;
    }
}

export function saveSessionValue(key: string, value: any): void {
    window.sessionStorage.setItem(key, JSON.stringify(value));
}

export function removeSessionValue(key: string) {
    window.sessionStorage.removeItem(key);
}

// session maps
const SaveMap = <V>(name: string, value: Map<string, V>): void => {
    saveSessionValue(name, Object.fromEntries(value));
}

const GetMap = <V>(name: string, defaultValue: Map<string, V> | null = null): Map<string, V> | null => {
    const obj = getSessionValue(name, {});
    if (obj === null)
        return null;
    return new Map(Object.entries(obj));
}

// custom session maps
export function getCommsMap(): Map<string, LastTouchedComm[]>{
    return GetMap("LastTouchedComms") ?? new Map();
}

export function saveCommsMap(map: Map<string, LastTouchedComm[]>) {
    SaveMap("LastTouchedComms", map);
}

export function getEnquiriesMap(): Map<string, EnquiryCache> {
    return GetMap("Enquiries") ?? new Map();
}

export function saveEnquiriesMap(map: Map<string, EnquiryCache>) {
    SaveMap("Enquiries", map);
}

// custom session keys
export function getActiveListEnquiries(): number[] | null {
    return getSessionValue<number[]>("ActiveListEnquiries");
}

export function saveActiveListEnquiries(activeEnquiries: number[]): void {
    saveSessionValue("ActiveListEnquiries", activeEnquiries);
}

export function getAdvancedSearchResults(): AdvancedSearchResults | null {
    return getSessionValue<AdvancedSearchResults>("advancedSearchResults");
}

export function saveAdvancedSearchResults(results: AdvancedSearchResults): void {
    saveSessionValue("advancedSearchResults", results);
}

// local storage
export function getLocalValue<T>(key: string, defaultValue: T | null = null): T | null {
    if (typeof window === undefined || typeof window === "undefined")
        return defaultValue;
    try {
        const item: string | null = window.localStorage.getItem(key);
        const tItem: T | null = JSON.parse(item ?? "null");
        if (tItem === null)
            return defaultValue;
        return tItem;
    } catch (error) {
        return defaultValue;
    }
}

export function saveLocalValue(key: string, value: any): void {
    window.localStorage.setItem(key, JSON.stringify(value));
}

export function removeLocalValue(key: string) {
    window.localStorage.removeItem(key);
}

export const getEnquiries = (enquryIds:number[]): iEnquiry[] =>{
    const enqCache =  new Map(store.getState().EnquiryCache.data.map(x=>[x.id,x])); 
    return enquryIds.map((id)=> enqCache.get(id)!) // im using the ! here because get returns a possible Undefined but i am passing a list of cases which must be in the list
}