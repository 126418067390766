import React, { useRef, useState } from 'react';
import { ChevronRight } from 'react-feather';
import { ReloadActiveList } from '../../Logic/Enquiries/Load';
import { removeNewEnquiryId } from '../../Logic/Enquiries/activateWizard';
import { deleteAttachmentDraft } from '../../Logic/GenericComms/createNote';
import { setLastCommunication } from '../../Logic/LastTouched/comm';
import { UploadMailBody } from '../../Logic/Mail/parts';
import { getNextStep } from '../../Logic/Wokflow/stepLogic';
import { WizardContext } from '../../contexts/wizard-context';
import { iEmail } from '../../interfaces/emailModel';
import { setShowEnquiry } from '../../state/restrictedViewSlice';
import { setWorkflowPopped, setWorkflowVisible } from '../../state/secondColSlice';
import { useAppDispatch } from '../../state/store';
import { removeTab } from '../../state/tabRow';
import { Wizard, actionEnquiryProcess, setEnquiryId } from '../../state/workflowSlice';
import { deleteEnquiryMailDrafts, getItem } from '../../utilities/localStore/indexDBCalls';
import { closeConnection } from '../../utilities/userProcessing';
import { removeConfirm } from '../../utilities/windowConfirm';
import Confirm from '../Generic/ConfirmDialog';
import './Workflow.scss';
import { StepProps } from './stepType';

function Template(props: StepProps) {
    const dispatch = useAppDispatch()
    const startRef = useRef<HTMLButtonElement>(null)
    let {wizard, onCommVerificationFailed} = React.useContext(WizardContext);
    wizard = JSON.parse(JSON.stringify(wizard));
    
    const cancelFunc = async () => {
       
        if (props.Popped == true) {
            showConfirmDialogue(wizard.EnquiryId,
                () => {
                    closeConnection(wizard?.workflow.data.EnquiryId).then((res) => {
                        removeConfirm();
                        window.opener.postMessage(
                            {
                                msg: "cancel",
                                EnquiryId: wizard?.workflow.data.EnquiryId
                            }, '*')
                        // remove other medium draft
                        if (wizard.otherMediumControl?.DraftID !== undefined && wizard.otherMediumControl?.DraftID !== 0) {
                            deleteAttachmentDraft(wizard.otherMediumControl?.DraftID)
                                .then(() => window.close());
                        }
                        else
                            window.close();
                    }).catch((err) => {
                        window.opener.postMessage(
                            {
                                msg: "cancel",
                                EnquiryId: wizard?.workflow.data.EnquiryId
                            }, '*')
                        window.close();
                    })
                    return;
                }, true);
        }
        else {
            showConfirmDialogue(wizard.EnquiryId,
                () => {
                    closeConnection(wizard?.workflow.data.EnquiryId).then(async () => {
                        // remove other medium draft
                        if (wizard.otherMediumControl?.DraftID !== undefined && wizard.otherMediumControl?.DraftID !== 0) {
                            deleteAttachmentDraft(wizard.otherMediumControl?.DraftID);
                        }
                        removeNewEnquiryId(wizard?.workflow.data.EnquiryId);

                        await ReloadActiveList();
                        
                        dispatch(setEnquiryId(1));
                        dispatch(removeTab(wizard?.workflow.data.EnquiryId));
                        dispatch(setWorkflowPopped(false));
                        dispatch(setWorkflowVisible(false));
                        if (props.FullWidth == false) { dispatch(setShowEnquiry(false)); }
                    }).catch((err) => {
                        console.log(err)
                    })
                }, true);
        }
    };

    const [dialogueShown, setDialogueShown] = useState({
        enquiryId: 0,
        okFunc: Function(),
        isShown: false
    });
    const showConfirmDialogue = (enquiryId: number, okFunc: Function, isShown: boolean) => {
        setDialogueShown({
            enquiryId,
            okFunc,
            isShown
        })
    }


    const finishFunc = async () => {
        const wiz: Wizard = JSON.parse(JSON.stringify(wizard));

        for (let { action, draft } of wizard.mailDrafts) {
            if (draft) {
                const ed: iEmail = await getItem("MailDrafts", wizard?.workflow.data.EnquiryId +"_"+action);
                if (ed) {
                    await UploadMailBody(wizard.workflow.data.EnquiryId, ed.maildraftId, ed).then((parts) => {
                        wiz.workflow.data.Drafts.push(draft!);
                    });
                }
            }
        }

        if (wiz.workflow.data.EnquiryAction === undefined) {
            alert("please select action");
            return;
        }
        const res = await actionEnquiryProcess(wiz.workflow.data, props.Popped);
        if (!res.isOk) {
            if (res.status === 400 && res.validationErrors?.errors["CommVerification"]?.length) {
                await onCommVerificationFailed();
            }
            else {
                alert("Failed to finish the wizard.");
            }
            return;
        }

        setLastCommunication(wizard.EnquiryId, 0);
        await deleteEnquiryMailDrafts(wizard?.workflow.data.EnquiryId);
        afterAction();
    }

    function afterAction() {

        if (props.Popped) {
            window.opener.postMessage(
                {
                    msg: "afterAction",
                    EnquiryId: wizard.EnquiryId,
                    UserId: wizard?.workflow.data.UserId
                }, '*') // cancel will cause the interface to refresh
            window.close();
        }
        else {
            ReloadActiveList();
            dispatch(removeTab(wizard?.workflow.data.EnquiryId));
            dispatch(setWorkflowVisible(false));
            if (props.FullWidth == false) { dispatch(setShowEnquiry(false)); }
        }
    }

    return (
        <>
        <div className={`step activeStep active`} style={{ backgroundColor: "#fff" }}>
            <div className="step--header" >
                <i className='chevron'><ChevronRight className="Chevron"></ChevronRight></i>
                <div className="flex-fill enqTitle">
                    {props.title}<br />
                </div>

                <div className="buttonRow" style={{overflowX: "hidden",height: "33px"}}>
                    <span tabIndex={0} onFocus={() => startRef.current?.focus()} />
                    {props.backF !== undefined && <button ref={startRef} className="g-btn g-btn-alternate" type="button" onClick={props.backF}>
                        Back
                    </button>}

                    {props.nextF !== undefined && <button className="g-btn g-btn-action" type="button" onClick={props.nextF} >
                       Next
                    </button>}
                    {getNextStep(props.step, wizard) == null && <button className="g-btn g-btn-action" type="button" onClick={ finishFunc} >
                       Finish
                    </button>}
                    {<button className="g-btn g-btn-alternate" type="button" onClick={cancelFunc} >
                        <label>Cancel</label>
                    </button>}
                </div>

            </div>
            <div className={"show"} style={{ backgroundColor: "#fff" }}>
                <div style={{ padding: "10px", height:"100%", overflow:"hidden", display: "flex", flexDirection:"column", paddingLeft: "28px", backgroundColor: "#fff" }}>
                    {props.children}
                </div>
                <span tabIndex={0} onFocus={() => startRef.current?.focus()} />
            </div>
        </div>
        
        {dialogueShown.isShown && <Confirm
            title={"Warning"}
            onCancel={() => showConfirmDialogue(0, dialogueShown.okFunc, false)}
            onConfirm={() => dialogueShown.okFunc()}
            messageType={"warn"}
        >{<>All processing done in the wizard will be lost if you close it at this point.             Are you sure that you wish to close this enquiry?'</>}</Confirm>}</>

    );
}

export default Template;
