import { BsPaperclip, BsSearch, BsX, BsCheck, BsFolder2Open } from "react-icons/bs";
import { iEmail, iLocalMessageAttachment } from "../../../interfaces/emailModel";
import React, { useRef } from "react";
import HtmlEditor from "../../HTMLEditor";
import Templates from "../ComposeEmailStep/templates";
import { createDraftId, getDraftId, saveDraftToIndexedDb } from "../../../Logic/Mail/draft";
import { inlineParts, insertAttachmentIntoIndexedDB, uploadFileViaXHR } from "../../../Logic/Mail/parts";
import { initDraftMailValues } from "../../../utilities/defaultValues";
import { addItem, getItem } from "../../../utilities/localStore/indexDBCalls";
import { iCommunication, iEnquiry } from "../../../interfaces/iEnquiry";
import { MisEntry } from "../../../interfaces/ActionStartResponse";
import { useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import { getAttachment } from "../../../Logic/GenericComms/CommParts";
import { getEnquiryById } from "../../../interfaces/enquiryList";
import { dateTimeFormat222, dateTimeFormatLong } from "../../Enquiries/enquiry/enquiry";
import EmailToolBar from "./emailToolBar";
import { getIcon } from "../../../components/Generic/mimeIcon";
import { formatEmailForComparison, formatEmailList, formatEmailforEmbed } from "../../../Logic/Mail/formatter";
import { htmlEncode } from "../../../utilities/string";
import AttachmentProgress from "./attachmentProgress";
import { unZipandReturnBlobUri } from "../../../utilities/gZip";
import AttachMessage from "./AttachMessage";
import PreviewMessage from "./previewMessage";
import { ToGenericComm,  mailComm, mediaComm, noteComm } from "../../../interfaces/iGenericComm";
import { EPriority } from "../../../interfaces/Priority";
import useOutsideClick from "../../../hooks/useOutsideClick";
import { iContactData } from "../../../interfaces/Contact";
import iEmailAddress from "../../../interfaces/iEmailAddress";
import { GetMyContacts } from "../../../Logic/Cache";
import { EmailDropdownInputWithPills } from "./EmailDropdownInputWithPills";
import { sortContacts, getEnquiryContacts } from "./EmailDropdownInputWithPills/contacts.helper";
import { WizardContext } from "../../../contexts/wizard-context";

type composerProps = {
    ActionId: number;
    showTemplates: boolean;
    Enquiry: iEnquiry;
    Communications: iCommunication[];
    MISData: Array<MisEntry>;
    defaultRecipient?: iEmailAddress | undefined;
    onTemplateApplied?: (feedbackDateTime?: string) => void;
}

function EmailComposer(props: composerProps) {
    const emailToEmbdefRef = useRef<HTMLSelectElement>(null);
    const attachmentRef = useRef<HTMLDivElement>(null);
    const embedMessageCM = useRef<HTMLDivElement>(null);
    const configuration = useSelector((state: RootState) => state.Configuration);
    const [displayTemplates, setDisplayTemplates] = React.useState(props.showTemplates);
    const [emailComposer, setEmailValues] = React.useState<iEmail>(initDraftMailValues);
    const [controlsCurrentValue, setControlsCurrentValue] = React.useState({to: "", cc: "", bcc: ""});
    const [allTemplates, setAlltemplates] = React.useState<Array<{ id: number, name: string }>>([])
    const [embededAttachments, setEmbededAttachments] = React.useState<Array<{ id: string, name: string, mimeType: string, size: number, }>>([]);
    const [selectedEmbeddedMessageId, setSelectedEmbeddedMessageId] = React.useState<number | undefined>(undefined);
    const [showEmbedMessage, setShowEmbedMessage] = React.useState(false);
    const [showEmbedMessageContextMenu, setShowEmbedMessageContextMenu] = React.useState({visible:false, x:0,y:0, data:0});
    const [bccShown, setBccShown] = React.useState<boolean>(false);
    const [messageToPreview, setMessageToPreview] = React.useState<mailComm | noteComm | mediaComm | undefined>(undefined);
    const [allContacts, setAllContacts] = React.useState<iContactData []>([]);

    let wizard = React.useContext(WizardContext).wizard;
    const enquiryUpdatedDetails: iEnquiry = {
        ...props.Enquiry,
        enquirerName: wizard.workflow.data.EnquirerDetails.FullName ?? props.Enquiry.enquirerName,
        enquirerEmail: wizard.workflow.data.EnquirerDetails.Email ?? props.Enquiry.enquirerEmail,
        subject: wizard.workflow.data.EnquirerDetails.Subject ?? props.Enquiry.subject
    };
    React.useEffect(() => {
        GetMyContacts().then((myCts) => {
            const enquiryCts: iEmailAddress[] = sortContacts(getEnquiryContacts(props.Enquiry,wizard ,  props.Communications));
            setAllContacts(mergeMapContacts(enquiryCts, myCts));
        })
    }, [props.Enquiry.id]);

    React.useEffect(() => {
        let tList: Array<{ id: number, name: string }> = [];
        (configuration.templates.filter(x => x.mailboxes.find(y => y === props.Enquiry.mailbox)).filter(z => z.actions?.find(c => c === props.ActionId)).map((item: any, index: number) => {
            tList.push({ id: item.id, name: item.name });
        }));
        if (tList.length > 0) {
            setAlltemplates(tList);
        }
        else {
            setDisplayTemplates(false);
        }
    }, []);

    React.useEffect(() => {
        (async () => {
            const draft = await getItem("MailDrafts", props.Enquiry.id + "_" + props.ActionId);
            if (draft) {
                setDisplayTemplates(false);
                setSelectedEmbeddedMessageId(draft.embeddedMessageId!);
                await checkInlineImages(draft);
            } else {
                let ed: iEmail = JSON.parse(JSON.stringify(emailComposer));

                if (props.defaultRecipient) {
                    ed.to = [{...props.defaultRecipient}];
                }
                
                ed.subject = wizard.workflow.data.EnquirerDetails.Subject;
                await checkInlineImages(ed);
            }
        })();
    }, []);

    React.useEffect(() => {
        (async () => {
            if (!displayTemplates) {
                await emEmail(emailComposer.embeddedMessageId!, JSON.parse(JSON.stringify(emailComposer)))
            }
        })();
    }, [displayTemplates]);

    const handlePriorityClick = (priority: EPriority) => {
        if (priority === emailComposer.priority) {
            setEmailValues(evs => ({...evs, priority: EPriority.NORMAL}));
            saveDraftToIndexedDb({...emailComposer, priority: EPriority.NORMAL}, false);
        }
        else {
            setEmailValues(evs => ({...evs, priority: priority}));
            saveDraftToIndexedDb({...emailComposer, priority: priority}, false);
        }
    }

    const doattachments = () => {
        let fileElem = document.getElementById("hiddenFile") as HTMLButtonElement;
        fileElem.click();

    }
    const embedMessage = () => {
            setShowEmbedMessage(true);
    }
    const updateEmbeddedAttachments = async () => {
        let ed = JSON.parse(JSON.stringify(emailComposer));
        if (embededAttachments.length > 0) {
            ed.includeEmbeddedMessageAttachments = false;
            setEmbededAttachments([]);
            setEmailValues(ed);
            saveDraftToIndexedDb(ed, false);
        }
        else {
            const embedComm = props.Communications.filter(z => z.email! != undefined).find(x => x.email!.id == selectedEmbeddedMessageId);
            if(embedComm){
                let emailToEmbed = ToGenericComm(embedComm);
                ed.includeEmbeddedMessageAttachments = true;
                setEmbededAttachments(await emailToEmbed.getAttachments());
                setEmailValues(ed);
                saveDraftToIndexedDb(ed, false);
            }
        }
    }
    const updateTemplateChanges = async (body: string, subject: string, showTemplates: boolean, feedbackDateTime?: string) => {
        let ed = emailComposer;
        ed.body = body;
        ed.subject = subject;
        let draft = await getItem("MailDrafts", props.Enquiry.id + "_" + props.ActionId)
        if (draft) {
            if (subject != '') {
                draft.subject = subject;
            }
            draft.body = body;
            await addItem("MailDrafts", draft);
            setEmailValues({ ...draft });
            setSelectedEmbeddedMessageId(undefined); // this check is needed to prevent mulitple checks. setting to undefined means it runs only once, and composer component is only loaded once this is complete
            setDisplayTemplates(false)
        }
        else {
            let ed = JSON.parse(JSON.stringify(emailComposer));
            ed.uId = props.Enquiry.id + "_" + props.ActionId;
            if (subject != '') {
                ed.subject = subject;
            }
            ed.enquiryId = props.Enquiry.id;
            ed.body = body;
            ed.maildraftId = await createDraftId(ed, props.Enquiry.id);
            await addItem("MailDrafts", ed);
            setEmailValues({ ...ed });
            setSelectedEmbeddedMessageId(undefined);
            setDisplayTemplates(false)
        }

        props.onTemplateApplied?.(feedbackDateTime);
    }

    const showTemplates = async (showTemplates: boolean) => {
        if (!showTemplates) {
            const draft = await getItem("MailDrafts", props.Enquiry.id + "_" + props.ActionId);
            if (draft) {
                setEmailValues(draft);
                setDisplayTemplates(false)
            }
        } else {
            setDisplayTemplates(true);
        }
    }
    const PopulateTemplateData = (subject: string, body: string) => {
        let ed = emailComposer;
        if (ed.enquiryId > 0) { // this check is to prevent the default values from being inserted into the DB
            ed.subject = subject;
            ed.body = body;
            setEmailValues({ ...ed });
        }
    }

    const handleInputChange = async <K extends keyof iEmail>(key: K, newValue: iEmail[K]) => {
        let ed: iEmail = await getItem("MailDrafts", props.Enquiry.id + "_" + props.ActionId);
        ed[key] = newValue;
        setEmailValues(ed);
        await saveDraftToIndexedDb(ed, false);
        return ed;
    }

    const doFileUpload = async (allFiles: FileList | null) => {
        attachmentRef.current!.className = "composeAttachmentRow";
        const draft = await getItem("MailDrafts", props.Enquiry.id + "_" + props.ActionId);
        let ec:iEmail = JSON.parse(JSON.stringify(draft));

        if (allFiles && allFiles.length) {
           
            for (var i = 0; i < allFiles.length + 1; i++) {
                if (allFiles.length == i) {
                    
                    for (var ii = 0; ii < ec.attachments.length; ii++) {
                        if(ec.attachments[ii].status=="pending"){
                        uploadFileViaXHR(null,ec.attachments[ii].id,ec.attachments[ii].uploadUrl!, ec);}
                        delete ec.attachments[ii].uploadUrl;
                    }
                    await addItem("MailDrafts", ec);
                    setEmailValues(ec);
                }
                else {
                    if (allFiles[i].name.length > 127) {
                        alert("file name too long");
                        //  return false;
                    }
                    ec = await insertAttachmentIntoIndexedDB(allFiles[i], ec,)
                }
            }
        }
    }

    const HandleFileAdded = async (e: React.ChangeEvent<HTMLInputElement>) => {
        doFileUpload(e.target.files);
    }

    const checkMailtoEmbed = async (ec: iEmail) => {
        let emails = props.Communications.filter(z => z.type == "Email");
        if (emails.length > 0) {
            let embeddedMessageId = undefined;
            if (emails.find(x => formatEmailForComparison(x.email!.from.address) == formatEmailForComparison(formatEmailList(ec.to)))) {
                embeddedMessageId = emails.find(x => formatEmailForComparison(x.email!.from.address) == formatEmailForComparison(formatEmailList(ec.to)))?.email!.id!
                if (embeddedMessageId == undefined) {
                    embeddedMessageId = emails[0].email?.id!;
                }
            }
            else {
                embeddedMessageId = emails.find(x => formatEmailForComparison(x.email!.from.address) == formatEmailForComparison(wizard.workflow.data.EnquirerDetails.Email))?.email!.id!
                if (embeddedMessageId == undefined) {
                    embeddedMessageId = emails.find(x => formatEmailForComparison(x.email!.from.address) == formatEmailForComparison(props.Enquiry.enquirerEmail))?.email!.id!
                }
                if (embeddedMessageId == undefined) { // it is possible that embbedded message may still be undefined at this point , this could happen if the details are changed outside the wizard etc
                    embeddedMessageId = emails.filter(x => x.email?.incoming == true)[0]?.email?.id
                }
            }

            await emEmail(embeddedMessageId === undefined ? 0 : embeddedMessageId, ec)
        }

    }
    const embedEmail = async (e: React.FormEvent<HTMLSelectElement>) => {
        let currentValue = parseInt(e.currentTarget.value);
        let ed = await getItem("MailDrafts", props.Enquiry.id + "_" + props.ActionId);
        await emEmail(currentValue, ed);
    }
    // i need to add this because when the component is loaded the firsttime i do no have hte latest draft yet because the state was not yet updated.  
    const emEmail = async (id: number, emailComp: iEmail) => {
        if (!displayTemplates) {
            if (id === undefined) {
                await checkMailtoEmbed(emailComp);
            } else {
                // debugger
                let eC = JSON.parse(JSON.stringify(emailComp));
                let embedTemplate = `<table cellpadding="2" cellspacing="0" border="0" width="100%" style="width:100%;border-left:2px solid #000  !important; font-size:12px;">
                                <tr>
                                    <td style="width:20px;"> <div ></div></td>
                                    <td style="border-top:0px solid #ccc  !important;">
                                    <br>
                                    <div >       ------- Original Message --------<BR>       <b>From: </b>  {from} <BR>       <b>To: </b>  {to} <BR>{cc}       <b>Sent: </b>  {sent} <BR>       <b>Subject: </b>  {subject}        <BR><BR>        {body}    </div></div>
                                    </td>
                                </tr>
                            </table>`;
                const embedComm = props.Communications.filter(z => z.email! != undefined).find(x => x.email!.id == id);
                let DOMParsing = new DOMParser();
                if (embedComm) {
                    let genericComm = ToGenericComm(embedComm);
                    const bodyToEmbed = await genericComm.getBody();
                    const attachmentsForEmbed = await genericComm.getAttachments();
                    let emailToEmbed = embedComm.email!;
                    
                    let cc = "";
                    if (emailToEmbed.cc?.length > 0) {
                        cc = htmlEncode(formatEmailforEmbed(emailToEmbed.cc));
                        cc = "       <b>Cc: </b>  " + cc + " <BR>";
                    }

                    embedTemplate = embedTemplate.replace("{from}", (emailToEmbed.from.name ? htmlEncode(emailToEmbed.from.name + " <" + emailToEmbed.from.address + ">") : emailToEmbed.from.address));
                    embedTemplate = embedTemplate.replace("{to}", htmlEncode(formatEmailforEmbed(emailToEmbed.to)));
                    embedTemplate = embedTemplate.replace("{cc}", cc);
                    embedTemplate = embedTemplate.replace("{sent}", dateTimeFormatLong(emailToEmbed.dateSent));
                    embedTemplate = embedTemplate.replace("{subject}", htmlEncode(emailToEmbed.subject));

                    let imgElements = DOMParsing.parseFromString(bodyToEmbed, "text/html");
                    let imgs = imgElements.querySelectorAll("img");
                    if (imgs.length > 0) {
                        for (var i = 0; i < imgs.length; i++) {
                            for (var c = 0; c < imgs[i].attributes.length; c++) {
                                if (imgs[i].attributes[c].name.toLowerCase() == "src") {
                                    if (imgs[i].attributes[c].value.indexOf("blob:") > -1 || imgs[i].attributes[c].value.indexOf("base64") > -1) {
                                        const res = await fetch(imgs[i].attributes[c].value)
                                        var blob = await res.blob();
                                        const dataUrl = URL.createObjectURL(blob);
                                        var randomId = Math.random().toString(16).slice(2);
                                        let maildraftId = await getDraftId(2, emailComp);
                                        let inlineA = await inlineParts(blob, maildraftId, dataUrl, randomId + "." + blob.type.substring(blob.type.indexOf("/") + 1), randomId);
                                        if(inlineA!=""){
                                            if (eC.inlineAttachments == undefined) {
                                                eC.inlineAttachments = [];
                                            }
                                            eC.inlineAttachments.push(inlineA);
                                            const titleNode = document.createAttribute("title");
                                            titleNode.value = randomId;
                                            imgs[i].attributes.setNamedItem(titleNode);
                                        }   
                                    }
                                }
                            }
                        }
               
                    }
                    embedTemplate = embedTemplate.replace("{body}", imgElements.documentElement.outerHTML);
                    if (formatEmailForComparison(formatEmailList(emailComp.to)) != formatEmailForComparison(emailToEmbed.from.address) && attachmentsForEmbed.length > 0) {
                        eC.includeEmbeddedMessageAttachments = true;
                        setEmbededAttachments(attachmentsForEmbed);
                    } else {
                        eC.includeEmbeddedMessageAttachments = false;
                        setEmbededAttachments([]);
                    }
                }
                else {
                    embedTemplate = '';
                }
                let parseddoc = DOMParsing.parseFromString(eC.body, "text/html");
                let parsed = parseddoc.querySelectorAll("div[id='EmbedDiv']");
                if (parsed.length > 0) {
                    parsed[0].innerHTML = embedTemplate;
                }
                eC.body = parseddoc.documentElement.outerHTML;
                eC.embeddedMessageId = id;
                setEmailValues(eC);
                setSelectedEmbeddedMessageId(id);
                saveDraftToIndexedDb(eC, false)
            }
        }
    }

    const removeAttachedEML = async (id: number) => {
        const draft: iEmail = await getItem("MailDrafts", props.Enquiry.id + "_" + props.ActionId);
        draft.attachedMessages = draft.attachedMessages?.filter(x => x != id);
        await saveDraftToIndexedDb(draft, false);
        setEmailValues(draft);
    }
    const checkInlineImages = async (ed: iEmail) => {
        if (ed.inlineAttachments ? ed.inlineAttachments!.length > 0 : false) {
            let DOMParsing = new DOMParser()
            let parsed = DOMParsing.parseFromString(ed.body, "text/html");
            let inlineimgages = parsed.querySelectorAll("img")!;
            for (var i = 0; i < ed.inlineAttachments!.length; i++) {
                await getItem("Blobs", ed.inlineAttachments![i]?.id).then(async (dbCall: any) => {
                    try {
                        if (ed.body.indexOf(ed.inlineAttachments![i].mimeContentID!) > 0) {
                            let iImgUrl = await unZipandReturnBlobUri(dbCall.data, ed.inlineAttachments![i].mimeType, ed.inlineAttachments![i].name);
                            for (var ii = 0; ii < inlineimgages.length; ii++) {
                                if (inlineimgages[ii].title == ed.inlineAttachments![i].mimeContentID) {
                                    ed.body = ed.body.replace(inlineimgages[ii].src, iImgUrl);
                                    ed.inlineAttachments![i].dataUrl! = iImgUrl;
                                }
                            }
                        }

                    } catch (err) {
                        console.error(err);
                    }
                });
            }
            setEmailValues(ed);
        } else {
            setEmailValues(ed);
        }
    }

    const doInlineImages = async (file: Blob, dataUrl: string, fileName: string, mimeContentID: string) => {
        let ed = await getItem("MailDrafts", props.Enquiry.id + "_" + props.ActionId);
        let inlineA = await inlineParts(file, ed.maildraftId, dataUrl, fileName, mimeContentID);
        if(inlineA!=""){
            if (ed.inlineAttachments == undefined) {
                ed.inlineAttachments = [];
            };
            ed.inlineAttachments?.push(inlineA);
            await saveDraftToIndexedDb(ed, false);
        }
        //setEmailValues(ed);
    }  //make changes to state 

    const bodyhandleChange = async (content: any) => {
        let ed = await getItem("MailDrafts", props.Enquiry.id + "_" + props.ActionId);
        ed.body = content;
        saveDraftToIndexedDb(ed, false,);
    }

    const previewMessage = (messageId:number)=>{
        let emails:Array<iCommunication> = props.Communications.filter(x=> x.type=="Email")!;
        const aaa = ToGenericComm(emails.find(x=> x.email?.id == messageId)!)
        setMessageToPreview(aaa);
    }
    const updateEmailcomposerState = async (ed: iEmail) => {
        await saveDraftToIndexedDb(ed, false);
        setEmailValues(ed);
    }

    const showEmbedMessageCM = (data: any) => (event: React.MouseEvent) => {
		// Disable the default context menu
		event.preventDefault();
		const newPosition = {
			x: event.pageX,
			y: event.pageY,
		};
		setShowEmbedMessageContextMenu({ visible: true, x: newPosition.x ,y:newPosition.y, data:data});
	};

	// Hide the custom context menu
	const hideContextMenuEmbedMessage = () => {
        setShowEmbedMessageContextMenu({ visible: false, x: 0 ,y:0, data:0});
	};
    useOutsideClick(embedMessageCM, hideContextMenuEmbedMessage);

    return (
        <>
            <div className='d-flex align-items-center justify-content-between'>
                <div>
                    <button className={displayTemplates ? "g-tab-selected template-tab-padding" : "g-tab template-tab-padding"} onClick={() => { showTemplates(true) }} type='button'>Available Templates</button>
                    <button className={!displayTemplates ? "g-tab-selected template-tab-padding" : "g-tab template-tab-padding"} type='button' onClick={() => { showTemplates(false); setSelectedEmbeddedMessageId(undefined) }}>Email Composer</button>
                </div>
                {displayTemplates == false && <div>
                    <div>
                        <EmailToolBar
                            uploadFunction={() => doattachments() }
                            embedMessageFunction={ props.Communications.filter(x => x.type=="Email").length>0? embedMessage : undefined }
                            onPriorityChange={handlePriorityClick}
                            emailPriority={emailComposer.priority}
                        />
                    </div>
                </div>}
            </div>
            {(displayTemplates == false &&
                <>
                    <div  className="mt-3 rowMarginControl">
                        <EmailDropdownInputWithPills
                            label={'To:'}
                            value={controlsCurrentValue.to}
                            values={emailComposer.to}
                            onChange={(newValue: string) => {
                                setControlsCurrentValue(ctr => ({ ...ctr, to: newValue }));
                            }}
                            onChangeValues={(newValues) => {
                                handleInputChange('to', newValues).then((ed) => {
                                    checkMailtoEmbed(ed);
                                });
                            }}
                            allContacts={allContacts}
                        />
                    </div>

                    <div className="rowMarginControl">
                        <EmailDropdownInputWithPills
                            label={'Cc:'}
                            value={controlsCurrentValue.cc}
                            values={emailComposer.cc}
                            onChange={(newValue: string) => {
                                setControlsCurrentValue(ctr => ({ ...ctr, cc: newValue }));
                            }}
                            onChangeValues={(newValues) => handleInputChange('cc', newValues)}
                            internalButton={bccShown ?
                                undefined :
                                {
                                    label: "Bcc",
                                    onClick: () => setBccShown(true)
                                }
                            }
                            allContacts={allContacts}
                        />
                    </div>

                    {bccShown && <div className="rowMarginControl">
                        <EmailDropdownInputWithPills
                            label={'Bcc:'}
                            value={controlsCurrentValue.bcc}
                            values={emailComposer.bcc}
                            onChange={(newValue: string) => {
                                setControlsCurrentValue(ctr => ({ ...ctr, bcc: newValue }));
                            }}
                            onChangeValues={(newValues) => handleInputChange('bcc', newValues)}
                            allContacts={allContacts}
                        />
                    </div>}

                    <div className="row rowMarginControl">
                        <div className='col-sm-1'><label className='g-label'>Subject:</label></div>
                        <div className='col-sm-11'><input type="text" className="g-TextBox-default" defaultValue={emailComposer.subject} onChange={(e) => handleInputChange('subject', e.target.value)} maxLength={250} id="eSubject" name="subject" autoComplete='off' aria-autocomplete='none' list='autocompleteOff'></input></div>
                    </div>
                </>)}
            {displayTemplates == false && (
                <div style={{ marginBottom: "8px" }} className={(emailComposer.attachments.length == 0 && 
                                                                 embededAttachments.length == 0 && 
                                                                 (emailComposer.attachedMessages? emailComposer.attachedMessages.length==0 : true  )) ? "composeAttachmentRowNoBorder" : "composeAttachmentRow"} ref={attachmentRef}>
                    {
                        emailComposer.attachments.length > 0 ?
                            (emailComposer.attachments.map((attachment: iLocalMessageAttachment, i: number) => {
                                return <AttachmentProgress attachment={attachment}
                                    emailComposer={emailComposer} finishFunc={updateEmailcomposerState} key={attachment.id} ></AttachmentProgress>
                            })) :
                            ""
                    }
                    {
                        emailComposer.attachedMessages ?
                            emailComposer.attachedMessages!.map((item) => {
                                return <div className="AttachmentItem" id='parentAtt_${aaa}' key={item} >
                                    {getIcon("email", "")}<div style={{ cursor:"pointer"}}> <span onClick={()=>{previewMessage(item)}} style={{color:"blue", fontWeight:"bold", cursor: "pointer"}} onContextMenu={showEmbedMessageCM(item)}> {
                                        (dateTimeFormat222(props.Communications.find(x => x.email?.id == item)?.dateStamp!) + " - " + props.Communications.find(x => x.email?.id == item)?.email?.from.name! + " - " + props.Communications.find(x => x.email?.id == item)?.email?.subject)
                                    }</span>
                                    </div>
                                    {
                                        <><div style={{ width: "1px", background: "#E4E4E4" }}  > </div>   <div style={{ cursor: "pointer" }} onClick={() => { removeAttachedEML(item) }}>&nbsp;<BsX style={{ fontSize: "15px" }}></BsX></div></>
                                    }

                                </div>
                            })
                            : ""
                    }
                    {
                        (
                            embededAttachments.length > 0 ? embededAttachments.map((attachment: any, i: number) => {

                                return <div className="AttachmentItem" key={attachment.id}>
                                    {getIcon(attachment.mimeType, attachment.name)}<div>    <span style={{ color: "blue", fontWeight: "bold" }} onClick={() => { getAttachment(attachment, 'open') }} >{attachment.name} </span>
                                        &nbsp;
                                        {` ${(attachment.size) > 999.99 ? ((attachment.size / 1000).toFixed(2)) + "MB" : (attachment.size).toFixed(2) + 'KB'}`}
                                    </div>
                                    <div>&nbsp;</div>
                                </div>;
                            }) : ""
                        )
                    }
                </div>

            )

            }


                {(displayTemplates == false && (emailComposer.body != "") && 
                                (props.Communications.length>0 ? 
                                    (selectedEmbeddedMessageId!=undefined) : true // if there are no communications to embed dont check for it. 
                                )
                            ) && <>
                        <HtmlEditor onChange={bodyhandleChange} initialValue={emailComposer.body} inlineImage={doInlineImages} attachmentHandler={doFileUpload}
                            inlineA={emailComposer.inlineAttachments!} mailbox={props.Enquiry.mailbox}  ></HtmlEditor>
                        <div className="row"><div className='col-sm-10'><br /></div></div>
                        <div className="row rowMarginControl">
                            <div className='col-sm-2'><label className='g-label'>Embed Email</label></div>
                            <div className='col-sm-10'>
                                <div className="controlWithButton">
                                    <div>
                                        <select className="g-Select-default" onChange={embedEmail} ref={emailToEmbdefRef} value={selectedEmbeddedMessageId}>
                                            <option value="0" key={324}> Don't embed any message </option>
                                            {
                                                props.Communications.filter(x => x.email! != undefined).map((item, index) => {
                                                    return <option value={item.email?.id} key={item.email?.id}   >{(dateTimeFormat222(item.dateStamp) + " - " +
                                                        item.email?.from.name! + " - " +
                                                        item.email?.subject)}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div >
                                        {
                                            props.Communications.find(x => x.email?.id == selectedEmbeddedMessageId) ? (
                                                props.Communications.find(x => x.email?.id == selectedEmbeddedMessageId)?.email?.hasAttachments ?
                                                    (
                                                        embededAttachments.length > 0 ? // this means embeded attachemnets have been added
                                                            <button onClick={() => { updateEmbeddedAttachments() }} type='button'><i className="embedGreenTickIcon"><BsCheck /></i><BsPaperclip style={{ width: "15px", color: "blue" }} /></button>
                                                            :
                                                            <button onClick={() => { updateEmbeddedAttachments() }} type='button'><i className="embedRedTickIcon"><BsX /></i><BsPaperclip style={{ width: "15px", color: "blue" }} /></button>
                                                    )
                                                    : (<button onClick={() => { return false; }} disabled={true} type='button' style={{ pointerEvents: "none" }}> <BsPaperclip style={{ width: "15px", color: "#8C8C8C", pointerEvents: "none" }} /></button>)
                                            ) : (<button onClick={() => { return false; }} disabled={true} type='button' style={{ pointerEvents: "none" }}> <BsPaperclip style={{ width: "15px", color: "#8C8C8C", pointerEvents: "none" }} /></button>)
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div id="hiddenFileUpload">
                            <input type="file" id="hiddenFile" onChange={HandleFileAdded} multiple></input>
                        </div>
                    </>
            }
            {(displayTemplates == true && allTemplates.length > 0)
                && <Templates
                    updateFunction={updateTemplateChanges}
                    Enquiry={enquiryUpdatedDetails}
                    MISData={props.MISData}
                    ActionId={props.ActionId}
                    PopulateTemplateData={PopulateTemplateData}
                    allTemplates={allTemplates}
                    subject={emailComposer.subject}></Templates>
            }

            {
                showEmbedMessage && <AttachMessage onCancel={() => { setShowEmbedMessage(false) }}  Enquiry={props.Enquiry} onConfirm={async (selectedValue) => {
                    if (selectedValue.length>0) {
                        let draft: iEmail = await getItem("MailDrafts", props.Enquiry.id + "_" + props.ActionId);
                        if(draft.attachedMessages==undefined){
                            draft.attachedMessages = [];
                        }
                        selectedValue.forEach((item) => {
                        if(draft.attachedMessages?.find(x => x==item)==undefined){
                               draft.attachedMessages?.push(item)
                        }
                        })
                        attachmentRef.current!.className = "composeAttachmentRow";
                        saveDraftToIndexedDb(draft, false);
                        setEmailValues(draft);                        
                    }
                    setShowEmbedMessage(false);
                }} Communications={props.Communications}></AttachMessage>
            }
{
                messageToPreview!=undefined && <PreviewMessage onCancel={() => { setMessageToPreview(undefined)}} 
                    comm={messageToPreview} FullWidth={true} enquirerName={wizard.workflow.data.EnquirerDetails.FullName} activeEnquiryId={props.Enquiry.id}
                ></PreviewMessage>
            }

{ showEmbedMessageContextMenu.visible && (
            <div style={{top:(showEmbedMessageContextMenu.y +10),left:(showEmbedMessageContextMenu.x), position:"fixed", float:"left"}}
                className="custom-context-menu"  ref={embedMessageCM}  >
              <div key='1' className="option" onClick={()=>{ previewMessage(showEmbedMessageContextMenu.data)}}>
                <i><BsFolder2Open /></i>
                    <div>Open</div>
                </div>
                <div>

                </div>
                <div key='2' className="option" onClick={()=>{ removeAttachedEML(showEmbedMessageContextMenu.data) }}>
                    <i><BsX /></i>
                    <div>Delete</div>
                </div> 

            </div>
        )}  
        </>
    )
}

function mergeMapContacts(enquiryContacts: iEmailAddress[], myContacts: iEmailAddress[]): iContactData[] {
    // union and map
    const newList: iContactData[] = [];
    enquiryContacts.forEach(ct => {
        const myFoundInd = myContacts.findIndex(ect => ect.address.toLowerCase() === ct.address.toLowerCase());
        newList.push(
            {
                ...ct,
                name: ct.name !== undefined && ct.name.trim() !== "" ?
                    ct.name :
                    myFoundInd !== -1 ? (
                        myContacts[myFoundInd].name !== undefined && myContacts[myFoundInd].name?.trim() !== "" ?
                            myContacts[myFoundInd].name :
                            ct.name
                    ) :
                        ct.name,
                inEnquiry: true,
                inMyContacts: myFoundInd !== -1
            }
        );
    })
    myContacts.forEach(ct => {
        if (enquiryContacts.find(ect => ect.address.toLowerCase() === ct.address.toLowerCase()) === undefined) {
            newList.push(
                {
                    ...ct,
                    inEnquiry: false,
                    inMyContacts: true
                }
            );
        }
    })
    return newList;
}

export default EmailComposer;