import * as React from 'react';
import './styles.scss'
import { useRef, useState } from 'react';
import Statuses, { Status } from '../../interfaces/Statuses';
import { AdvancedSearchPM } from '../../interfaces/AdvancedSearch/AdvancedSearchPM';
import SearchFieldRowText from './SearchFieldRowText';
import SearchFieldRowTextWithClause from './SearchFieldRowTextWithClause';
import SearchFieldRowUser from './SearchFieldRowUser';
import SearchFieldRowDropDown from './SearchFieldRowDropDown';
import SearchFieldRowDate from './SearchFieldRowDate';
import SearchFieldRowNumber from './SearchFieldRowNumber';
import { GetSearchFieldHistory,  UpdateSearchFieldHistory } from '../../Logic/Search';
import { RootState } from '../../state/store';
import { SearchFieldPM } from '../../interfaces/AdvancedSearch/SearchFieldPM';
import { iUser } from '../../interfaces/iUser';
import { useSelector } from 'react-redux';
import ConfirmDialog from '../Generic/ConfirmDialog';
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback } from '../../error-boundary/errorFallback'

function GetDateString(date: Date): string {

    let result = date.getFullYear().toString();

    result += "-" + ((date.getMonth() + 1) < 10 ? "0" : "") + (date.getMonth() + 1);
    result += "-" + ((date.getDate() < 10) ? "0" : "") + date.getDate();
    result += "T" + (date.getHours() < 10 ? "0" : "") + date.getHours()
    result += ":" + (date.getMinutes() < 10 ? "0" : "") + date.getMinutes()

    return result;
}

function DefaulAfterDate(): string {
    let result = new Date();
    result.setHours(0, 0);
    return GetDateString(result);
}

function DefaulBeforeDate(): string {
    let result = new Date();
    result.setHours(23, 59);
    return GetDateString(result);
}

function GetInitialQuery(): AdvancedSearchPM {
    return {
        EnquiryReference: {
            Enabled: false,
            Label: "Reference Number",
            Value: "",
        },
        OtherReference: {
            Enabled: false,
            Label: "Policy Number",
            Value: "",
            IsContains: false,
        },
        EnquirerName: {
            Enabled: false,
            Label: "Enquirer Name",
            Value: "",
            IsContains: false,
        },
        EmailAddress: {
            Enabled: false,
            Label: "Email Address",
            Value: "",
            IsContains: false,
        },
        Subject: {
            Enabled: false,
            Label: "Subject Line",
            Value: "",
            IsContains: true,
        },
        Mailbox: {
            Enabled: false,
            Label: "Mailbox",
            Value: "",
        },
        Body: {
            Enabled: false,
            Label: "Body",
            Value: "",
            IsContains: true,
        },
        Owner: {
            Enabled: false,
            Label: "Owner",
        },
        EnquiryStatus: {
            Enabled: false,
            Label: "Enquiry Status",
            Value: Statuses.Received.ID,
        },
        ReceivedAfter: {
            Enabled: false,
            Label: "Received After",
            Value: DefaulAfterDate(),
        },
        ReceivedBefore: {
            Enabled: false,
            Label: "Received Before",
            Value: DefaulBeforeDate(),
        },
        ReturnFirst: {
            Enabled: true,
            Label: "Return First",
            Value: 100,
        },
    }
};

export interface AdvancedSearchProps {
    onClose: () => void,
    onSearch: (query: AdvancedSearchPM) => Promise<void>,
    queryInitInterceptor?: (query: AdvancedSearchPM) => void | undefined,
    loadQuery: () => AdvancedSearchPM | undefined | null,
    persistQuery: (query: AdvancedSearchPM) => void,
}

function AvancedSearch(props: AdvancedSearchProps) {
    const [query, setQueryState] = useState(GetInitialQuery());
    const queryRef = useRef(query); // used so that calling onSearch from window key press uses correct query.

    const [showBodyWarning, setShowBodyWarning] = useState(false);
    const [focusBody, setFocusBody] = useState(false);

    const currentUser = useSelector((state: RootState) => state.UserInformation);
    const permissions = useSelector((state: RootState) => state.permissions);

    const [history, setHistory] = useState({
        EnquiryReference: new Array<string>(),
        OtherReference: new Array<string>(),
        EnquirerName: new Array<string>(),
        EmailAddress: new Array<string>(),
        Subject: new Array<string>(),
        Body: new Array<string>(),
        Owner: new Array<iUser>()
    });

    React.useEffect(() => {
        let savedHistory = GetSearchFieldHistory();

        let currentUserHistoryItem = savedHistory.Owner.find(x => x.id === currentUser.userID);
        if (currentUserHistoryItem == undefined) {
            savedHistory.Owner.push({
                id: currentUser.userID,
                firstname: currentUser.firstname,
                lastname: currentUser.lastname
            });
        }
        setHistory(savedHistory);

        const savedQuery = props.loadQuery();
        if (savedQuery !== undefined && savedQuery !== null) {
            setQueryState(savedQuery);
        }
        else {
            resetQuery();
        }
    }, []);

    React.useEffect(() => {

        const keyDownHandler = async (e: KeyboardEvent) => {
            if (e.key === "Enter") {
                e.preventDefault();
                await onSearch();
            }
            else if (e.key === "Escape") {
                e.preventDefault();
                props.onClose();
            }
        };

        document.addEventListener("keydown", keyDownHandler);

        return () => {
            document.removeEventListener("keydown", keyDownHandler);
        };
    }, []);

    React.useEffect(() => {
        queryRef.current = query
        props.persistQuery(query);
    }, [query]);

    const onUserChange = async (owner: SearchFieldPM) => {
        setQueryState({ ...query, Owner: owner })
    }

    function resetQuery() {
        let initQuery = GetInitialQuery();
        initQuery.Owner.Value = {
            id: currentUser.userID,
            firstname: currentUser.firstname,
            lastname: currentUser.lastname
        };

        props.queryInitInterceptor?.(initQuery);

        setQueryState(initQuery);
    };

    const onSearch = async () => {
        UpdateSearchFieldHistory(queryRef.current);
        await props.onSearch(queryRef.current);
    };

    function disableBody() {
        setQueryState({ ...query, Body: {...query.Body, Enabled: false} });
    }

    function onBodyEnabled(value: SearchFieldPM) {
        if (!query.Body.Enabled && value.Enabled) {
            setShowBodyWarning(true);
        }

        setQueryState({ ...query, Body: value });
    }

    return (
        <>
            {showBodyWarning &&
                <ConfirmDialog
                    title='Warning'
                    onConfirm={() => {
                        setFocusBody(true);
                        setShowBodyWarning(false);
                    }}
                    onCancel={() => {
                        disableBody();
                        setShowBodyWarning(false);
                    }}
                >
                    <p>This search method is very slow and places a serious load on the system. Only use this search method when no other search method will give the results needed.</p>
                    <p>Are you sure you wish to use this search method?</p>
                </ConfirmDialog>
            }
            <div>
                <SearchFieldRowText searchField={{...query.EnquiryReference}} onChange={(e) => setQueryState({ ...query, EnquiryReference: e })} history={history.EnquiryReference} />
                <SearchFieldRowTextWithClause searchField={{...query.OtherReference}} onChange={(e) => setQueryState({ ...query, OtherReference: e })} history={history.OtherReference} />
                <SearchFieldRowTextWithClause searchField={{...query.EnquirerName}} onChange={(e) => setQueryState({ ...query, EnquirerName: e })} history={history.EnquirerName} />
                <SearchFieldRowTextWithClause searchField={{...query.EmailAddress}} onChange={(e) => setQueryState({ ...query, EmailAddress: e })} history={history.EmailAddress} />
                <SearchFieldRowTextWithClause searchField={{...query.Subject}} onChange={(e) => setQueryState({ ...query, Subject: e })} history={history.Subject} />
                <SearchFieldRowUser searchField={{...query.Owner}} onChange={onUserChange} history={history.Owner} />
                <SearchFieldRowDropDown searchField={{...query.EnquiryStatus}} onChange={(e) => setQueryState({ ...query, EnquiryStatus: e })}>
                    {
                        Object.entries(Statuses).map((item: [string, Status]) =>
                            <option key={item[1].ID} value={item[1].ID}>{item[1].Name}</option>
                        )
                    }
                </SearchFieldRowDropDown>
                <SearchFieldRowDate searchField={{...query.ReceivedAfter}} onChange={(e) => setQueryState({ ...query, ReceivedAfter: e })} />
                <SearchFieldRowDate searchField={{...query.ReceivedBefore}} onChange={(e) => setQueryState({ ...query, ReceivedBefore: e })} />
                {permissions.Tenant.SearchMailBody ? <SearchFieldRowTextWithClause searchField={{...query.Body}} onChange={(e) => onBodyEnabled(e)} history={history.Body} setFocus={focusBody} /> : <></>}
                <SearchFieldRowNumber searchField={{...query.ReturnFirst}} min={1} max={1000} onChange={(e) => setQueryState({ ...query, ReturnFirst: e })} />
                <div className="search-buttons">
                    <ErrorBoundary FallbackComponent={ErrorFallback}>
                        <button key="Action" className="g-btn g-btn-action" onClick={onSearch}>Search</button>
                    </ErrorBoundary>
                    <button key="Cancel" className="g-btn g-btn-alternate" onClick={resetQuery}>Reset</button>
                </div>
            </div>
        </>
    )
}

export default AvancedSearch;
