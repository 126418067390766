import '../../Email/email.scss';
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from '../../../state/store'
import Preview from '../../Email/preview';
import Enquiry from '../../Enquiries/enquiry/enquiry'
import { iEnquiry } from '../../../interfaces/iEnquiry';
import { iGenericComm, ToGenericComm } from '../../../interfaces/iGenericComm';
import { useState} from 'react';
import { WizardContext } from '../../../contexts/wizard-context';
import React from 'react';
import { getLocalValue } from '../../../utilities/localStore/calls';
import { iLocalMessage } from '../../../interfaces/emailModel';
import { GetEnquiryCommunications } from '../../../Logic/EmsAPI/Enquiries';
import { updateCommunicationCache } from '../../../state/communicationsCache';

type switchProps = {
    FullWidth: boolean,
    enquiry: iEnquiry,
    interface?: string
}


function EnquiryWrapper(props: switchProps) {

    const dispatch = useAppDispatch()
    const [commToView, setcommToView] = useState<iGenericComm | undefined>(undefined);
    const communicationsCache = useSelector((state: RootState) => state.CommunicationsCache);
    const enquiriesCache = useSelector((state: RootState) => state.EnquiryCache.data);
    const communications = communicationsCache.data.find(x=>x.enquiryId == props.enquiry.id)?.communications
     function changeCommToView(commId:number){
        setcommToView(ToGenericComm(
            communications?.find(x => x.id == commId)!
        ));
    }
    
        const {wizard} = React.useContext(WizardContext);
        let previewComponent = 
        (communications && communications.length >0 && commToView) ?
        <Preview comm={commToView} FullWidth={props.FullWidth} enquirerName={props.enquiry?.enquirerName} activeEnquiryId={props.enquiry.id}/> : undefined
        
    
        if (wizard !== undefined) {
            const localAttachments = getLocalValue<iLocalMessage[]>("localAttachments") ?? [];
            let localMessage = localAttachments.find(x => x.communicationId === wizard.lastCommunication?.id)
    
            if (localMessage !== undefined) {
                let comm = ToGenericComm(wizard?.lastCommunication!);
                previewComponent = <Preview comm={comm} FullWidth={props.FullWidth} enquirerName={props.enquiry?.enquirerName} activeEnquiryId={props.enquiry.id}/>
            }
        }

React.useEffect(() => {
       const checkLastComm = async () =>{ 
        let commsForEnquiry = communicationsCache.data.find(x=>x.enquiryId == props.enquiry.id);

        // Set if existing comms found and up to date.
        if (commsForEnquiry && commsForEnquiry.cachedAt == props.enquiry.commUpdatedAt) {
            setcommToView(ToGenericComm(commsForEnquiry.communications[0]));
            return;
        }

        // Fetch latest comms.
        let commResponse = await GetEnquiryCommunications(props.enquiry.id);
        if (!commResponse.isOk) {
            throw new Error(`${commResponse.status}  ${commResponse.statusText} attempted to get comms on enquiryWrapper `); // the error boundary will then trap this error
        }
        else if (!commResponse.data) {
            throw new Error(`${commResponse.statusText} no data returned from server when getting comms on enquiryWrapper`); // the error boundary will then trap this error
        }

        dispatch(updateCommunicationCache({
            enquiryId: props.enquiry.id,
            communications: commResponse.data,
            cachedAt:props.enquiry.commUpdatedAt
        }));

        if(commResponse.data.length>0) {
            setcommToView(ToGenericComm( commResponse.data[0]));
        }
    }
    checkLastComm();
},[props.enquiry.commUpdatedAt, props.enquiry.id])
    return (
        <div className='enquiry-history'  >
          
            <div className='enquiry-history--enquiry'>
            {  (communications ) && <Enquiry
                    key={"key_" + props.enquiry?.id}
                    e={props.enquiry}
                    interface={props.interface}
                    activeCommId={commToView?.id ?? 0}
                    commOnclick={(e:number)=>{changeCommToView(e)}}
                    hasActivateWiz={false}
                    communications={communications}
                />
                }
            </div>
            
            {
            Object.keys(props.enquiry).length>0 ?
                (communications  &&  previewComponent) :
                null
        } 
        </div>
    )
}

export default EnquiryWrapper;