import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { EnquiryGridSelectionContext, gridSelectionProperties } from '../../../contexts/enquiry-grid-selection.context';
import { getLastTouched, saveLastTouched } from '../../../Logic/LastTouched/misc';
import { RootState } from '../../../state/store';
import { getActiveListEnquiries, saveActiveListEnquiries } from '../../../utilities/localStore/calls';
import { sortByField, SortEnquiriesParams, sortOwner, sortStatus } from '../../../utilities/sort';
import EnquiryGrid from '../enquiryGrid';

function Results(props: any) {
    const activeEnquiry = useSelector((state: RootState) => state.activeEnquiry);
    const enquiriesCache = useSelector((state: RootState) => state.EnquiryCache.data);     

    const [enquiries, setEnquiries] = useState(new Array<any>())
    const [sortBy, setSortBy] = useState<SortEnquiriesParams>({orderBy: null});
    const {selectionProperties, SetEnquiryGridSelectionProperties} = useContext(EnquiryGridSelectionContext)
    
    useEffect(() => {
        const activeList = getActiveListEnquiries() ?? [];

        const enqCache =  new Map(enquiriesCache.map(x => [x.id, x])); 
        const updatedEnquiries = activeList
            .map((id)=> enqCache.get(id))
            .filter(x=> x)
            .map(x => x!);

        const LastTouched = getLastTouched();
        const lastSort = LastTouched.SearchResults.EnquiryLists[0].orderBy ?? { orderBy: "dateReceived", desc:true }
        
        setEnquiries(updatedEnquiries);
        setSortBy(lastSort);

        if (selectionProperties.multiSelect) {
            SetEnquiryGridSelectionProperties(currentState => {
                const activeSet = new Set<string>(updatedEnquiries.map(x => x.id.toString())); // did this for speed.
                const previousSet = new Set<string>(currentState.selectedEnquiries);
                const updatedSelection = activeSet.intersection(previousSet);

                return {
                    ...currentState,
                    selectedEnquiries : Array.from(updatedSelection)
                };
            });
        }
    }, [enquiriesCache]);

    useEffect(() => {
        const LastTouched = getLastTouched();
        setSortBy(LastTouched.SearchResults.EnquiryLists[0]?.orderBy ?? {orderBy: null});
    }, []);

    const handleHeaderClick = (fieldName: string): any => {
        let updatedEnquires = [...enquiries];
        const lastTouched = getLastTouched();
        const activeList = lastTouched.SearchResults.EnquiryLists[0];

        activeList.orderBy ??= { orderBy: null };

        if (activeList.orderBy.orderBy == fieldName) {
            activeList.orderBy = {
                orderBy: fieldName,
                desc: !activeList.orderBy.desc,
            };
        } else {
            activeList.orderBy = {
                orderBy: fieldName,
            };
        }

        const order = activeList.orderBy;
        if (order.orderBy) {
            if (order.orderBy == "owner") {
                updatedEnquires = sortOwner(updatedEnquires, order.desc);
            } else if (order.orderBy == "status") {
                updatedEnquires = sortStatus(updatedEnquires, order.desc);
            } else {
                updatedEnquires = sortByField(updatedEnquires, order.orderBy, order.desc);
            }
            
        	saveActiveListEnquiries(updatedEnquires.map(x => x.id));
        	setEnquiries(updatedEnquires);
        }

        saveLastTouched(lastTouched);
        setSortBy(activeList.orderBy);
    };

    const multiSelectOnclick = (selectionProperties:gridSelectionProperties)=>{
        SetEnquiryGridSelectionProperties(currentState=>{
            currentState = JSON.parse(JSON.stringify(currentState));
            currentState.multiSelect = selectionProperties.multiSelect;
            currentState.selectedEnquiries = selectionProperties.selectedEnquiries;
        return currentState;
        }) ;
    }
    return (
        <>
        {( enquiries.length > 0) &&
            <EnquiryGrid data={enquiries} theme="dataTable_default" 
                sortFunction={handleHeaderClick}
                sortCol = {sortBy}
                selectedEnquiry={activeEnquiry.id}
                columns={[ "verticalSPacer",
                             "priority",
                            "hasAlarm",
                            "hasUnworkedAttachments",
                            "enquiryReference",
                            "owner",
                            "enquirerName",
                            "dateReceived",
                            "activeSlaCycle.timeout",
                            "status",
                            "subject",          
                            "dateCompleted",
                            "workItems"       
                        ]}
                        multiSelect={selectionProperties.multiSelect}
                        selectedItems={selectionProperties.selectedEnquiries}
                        updateSelectedItems={multiSelectOnclick}
            ></EnquiryGrid>
            }
            </>
        )
}

export default Results;