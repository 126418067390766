import { Delete, callApi, iRequest } from "../utilities/apiCall"
import { getActiveListEnquiries, getEnquiries, getSessionValue, saveSessionValue } from "../utilities/localStore/calls"
import { iEnquiry,iEnquiries } from "./iEnquiry"
import { setItemEnabled } from "../utilities/loading";
import { getLastTouched, saveLastTouched } from "../Logic/LastTouched/misc";
import store from "../state/store";

export interface Enquiries {
    enquiries: Array<EnquiryList>
}

export interface EnquiryList {
    title: string,
    enquiries: Array<iEnquiry>
}

export const getActiveEnquiries =(): iEnquiries =>{
         const ActiveList = getActiveListEnquiries();
        const allEnquiries = getEnquiries(ActiveList?? []);
        return {"enquiries":allEnquiries}
 
}

export const getActiveList = (actList: string) => {
    const iLastTouched = getLastTouched();

    if (actList == "Mailboxes") {
        return iLastTouched.Queue.ActiveList + ""
    }
    else if (actList == "My Enquiries") {
        return iLastTouched.MyEnquiries.ActiveList
    }
    return ""
}

export const SetLastTouchedEnquiry = (enquiryId: number, commId: number) => {
    const siteInformation = store.getState().SiteInformation;
    const lastTouched = getLastTouched();

    if (siteInformation.enquiriesListVisible) {
        const touchedList = lastTouched.MyEnquiries.EnquiryLists.find(x => x.Name === lastTouched.MyEnquiries.ActiveList)!;
        touchedList.EnquiryId = enquiryId;
        touchedList.CommId = commId;
    } else if (siteInformation.queuesListVisible) {
        const touchedList = lastTouched.Queue.EnquiryLists.find(x => x.Name === lastTouched.Queue.ActiveList)!;
        touchedList.EnquiryId = enquiryId;
        touchedList.CommId = commId;
    } else if (siteInformation.searchResultsVisible) {
        const touchedList = lastTouched.SearchResults.EnquiryLists[0];
        touchedList.EnquiryId = enquiryId;
        touchedList.CommId = commId;
    }

    saveLastTouched(lastTouched);
}

export const getLastActiveList = () => {
    const iLastTouched = getLastTouched();
    const actList = iLastTouched.lastActiveListType
    if (actList == "Mailboxes") {
        return iLastTouched.Queue.ActiveList + ""
    }
    else if (actList == "My Enquiries") {
        return iLastTouched.MyEnquiries.ActiveList
    }
    return ""
}

export async function activateEnquiryWizard(enquiryId: number, id: string = ""): Promise<any> {
    setItemEnabled(id, false)
    if (document.getElementById("loader")) {
        (document.getElementById("loader") as HTMLElement)!.style.display = "block";
    }
    var promise = new Promise<any>((resolve, reject) => {

        let request: iRequest = {
            url: `Enquiry/${enquiryId}/Action`,
            method: "POST",
            headers: [{ key: "Content-Type", value: "application/json" }],
            body: "{}",
            responseType: "json"
        }
        callApi(request).then((resp) => {
            setItemEnabled(id, true)

            resolve(resp);
        }).catch((err) => {
            reject(err);
            setItemEnabled(id, true)
        });

    })
    return promise;
}
export async function deactivateEnquiryWizard(enquiryId: number, id: string = ""): Promise<any> {
    setItemEnabled(id, false)
    if (document.getElementById("loader")) {
        (document.getElementById("loader") as HTMLElement)!.style.display = "block";
    }
    
    try {
        await Delete(`Enquiry/${enquiryId}/Action`);
    } catch { } // Do nothing as enquiry is probably no longer locked anyway.
    
    setItemEnabled(id, true);
}

export const getEnquiryById = (id: number): iEnquiry | undefined => {
    let Enquiry: iEnquiry | undefined;
    const enquiries = store.getState().EnquiryCache.data;
    Enquiry = enquiries.find(x=>x.id==id);
    return Enquiry;
}

export const scrollToView = (elementId: string, scrollParentId: string) => {

    let elementContainer = document.getElementById(scrollParentId)!;
    let elementHtml: HTMLElement = document.getElementById(elementId)!;
    if (elementContainer != null && elementHtml != null) {
        if (!isWithinParentView(elementHtml, elementContainer)) {
            if (elementHtml.offsetTop > 400) {
                elementContainer.scrollTop = elementHtml.offsetTop - 400;
            }
            else {
                elementContainer.scrollTop = 0;
            }
        }
    }
}

const getMaxScroll = (container: HTMLElement) => {
    return container.scrollHeight - container.clientHeight;
}

export const isWithinParentView = (elt: HTMLElement, parent: HTMLElement) => {
    const eltRec = elt.getBoundingClientRect();
    const parRec = parent.getBoundingClientRect();

    const eltAbove = eltRec.bottom < parRec.top
    const eltBelow = eltRec.top > parRec.bottom

    return !(eltAbove || eltBelow)
}