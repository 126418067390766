import Confirm from "../Generic/ConfirmDialog";
import { iUser } from "../../interfaces/iUser";
import { iActionProps, iEnquiry } from "../../interfaces/iEnquiry";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from '../../state/store'
import { RemoveEnquiriesRequest, RemoveEnquiries as RemoveEnquiriesCall } from "../../Logic/EmsAPI/Enquiries";
import { logErrorToService } from "../../error-boundary/logerror";

interface Props  extends iActionProps{
    enquiries: Array<iEnquiry>,
    UserList?: iUser[] | undefined,
}

 function RemoveEnquiries(props: Props) {  
    const userID = useSelector((state: RootState) => state.UserInformation).userID;
    const dispatch = useAppDispatch();

    async function UpdateEnquiryStatus() {
        const chunkSize = 20;
        const requests = new Array<RemoveEnquiriesRequest>();

        // Chunk the requests into batches
        for (let i = 0; i < props.enquiries.length; i += chunkSize) {

            let request: RemoveEnquiriesRequest = {
                Enquiries: props.enquiries.slice(i, i + chunkSize).map(enq => enq.id)
            }

            requests.push(request);
        }

        // Send the requests in parallel
        const responses = await Promise.all(requests.map(request => RemoveEnquiriesCall(request)));

        // Check for errors
        if (responses.some(response => !response.isOk)) {

            let errorLogID: string | undefined = undefined;

            // Create composite error to group all error log IDs
            if (responses.length > 1) {
                const compositeError = JSON.stringify(
                    responses
                        .filter(response => response.errorLogID)
                        .map(response => response.errorLogID));

                errorLogID = logErrorToService(new Error(compositeError));
            } else if (responses.length === 1) {
                errorLogID = responses[0].errorLogID;
            }

            if (responses.every(response => !response.isOk)) {
                props.onError("Unable to remove enquiries.", errorLogID);
            } else {
                props.onError("Some enquiries were not removed.", errorLogID);
            }
            
            return;
        }
        
        props.onCreated();
    }

    return (<><Confirm title="Warning"
        onCancel={() => props.onCancel()}
        onConfirm={() => { UpdateEnquiryStatus() }}
    >
        <>Are you sure you want to remove the enquiry/enquiries from the list?</>
        </Confirm> <></>
 </>
 )
}
export default RemoveEnquiries;