import { useContext } from 'react';
import { Col, Row } from "react-bootstrap";
import { BsX } from "react-icons/bs";
import { EnquiryGridSelectionContext } from '../../../contexts/enquiry-grid-selection.context';
import { AdvancedSearchPM } from "../../../interfaces/AdvancedSearch/AdvancedSearchPM";
import { LoadSearchResults } from '../../../Logic/Enquiries/Load';
import { getLastTouched, saveLastTouched } from '../../../Logic/LastTouched/misc';
import { setWorkflowVisible } from "../../../state/secondColSlice";
import { setSearchResultsVisibility } from "../../../state/siteSlice";
import { useAppDispatch } from "../../../state/store";
import { switchFirstTab } from "../../../state/tabRow";
import { getSessionValue, saveSessionValue } from "../../../utilities/localStore/calls";
import { resetSort } from '../../../utilities/sort';
import AvancedSearch from "../../AdvancedSearch";
import './topBar.scss';

export interface AvancedSearchDialogProps {
    onClose: () => void,
    fullWidth: boolean,
}

function AvancedSearchDialog(props: AvancedSearchDialogProps) {
    const dispatch = useAppDispatch();
    const {SetEnquiryGridSelectionProperties} = useContext(EnquiryGridSelectionContext);
    
    const onSearch = async (query: AdvancedSearchPM) => {
        resetSort();
        SetEnquiryGridSelectionProperties({
            multiSelect : false,
            selectedEnquiries : []
        });

        const iLastTouched = getLastTouched();
        iLastTouched.lastActiveListType = "Results";
        saveLastTouched(iLastTouched);

        await LoadSearchResults(query);
        
        dispatch(setWorkflowVisible(false));
        dispatch(setSearchResultsVisibility());
        dispatch(switchFirstTab("Search Results"));

        props.onClose();
    };

    const persistQuery = (query: AdvancedSearchPM) => {
        saveSessionValue("advancedSearchQuery", query);
    };

    const loadQuery = () => {
        return getSessionValue<AdvancedSearchPM>("advancedSearchQuery")
    };

    return (
        <>
            <div className="offClick" onClick={props.onClose} />
            <div className="advanced-search-dialog">
                <Row>
                    <Col xs={10} className="search-label-col search-title-col"><label className="search-field-label search-title"><b>Search Enquiries</b></label></Col>
                    <Col xs={2} className="search-close-column">{props.onClose !== undefined ? <BsX onClick={props.onClose} /> : <></>}</Col>
                </Row>
                <AvancedSearch onClose={props.onClose} onSearch={onSearch} persistQuery={persistQuery} loadQuery={loadQuery} />
            </div>
        </>
    );
}


export default AvancedSearchDialog;