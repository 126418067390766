 import { useContext, useEffect, useState } from "react";
import { BsArrowClockwise, BsCheck2Square } from 'react-icons/bs';
import { useSelector } from "react-redux";
import { burgerMenuMatchLeftPanePos } from "../../../Logic/BurgerMenu";
import { ReloadSearchResults } from "../../../Logic/Enquiries/Load";
import { EnquiryGridSelectionContext } from '../../../contexts/enquiry-grid-selection.context';
import { useWindowResize } from "../../../hooks/useWindowResize";
import Statuses, { Status } from "../../../interfaces/Statuses";
import { getEnquiryById } from "../../../interfaces/enquiryList";
import { setShowEnquiry, setShowWizard } from "../../../state/restrictedViewSlice";
import { setSearchResultsVisibility } from "../../../state/siteSlice";
import { RootState, useAppDispatch } from "../../../state/store";
import { showHideLoader } from "../../../utilities/loading";
import { getActiveListEnquiries, getAdvancedSearchResults } from "../../../utilities/localStore/calls";
import { resetSort } from "../../../utilities/sort";
import { EnquiryBurgerMenu } from "../../Menu";

function Query({ FullWidth }: { FullWidth: boolean }) {
    const dispatch = useAppDispatch();
    const RestrictedWidthState = useSelector((state: RootState) => state.restrictedView)
    const enquiriesCache = useSelector((state: RootState) => state.EnquiryCache.data);

    let sValues: JSX.Element[] = [];
    const activeEnquiry = useSelector((state: RootState) => state.activeEnquiry);
    const searchResults = getAdvancedSearchResults();
    const tabState = useSelector((state: RootState) => state.tabRow.tabs).find(x=>x.active==true);
    let eNQ = getEnquiryById(tabState?.enquiryId!);
    const [enabled, setEnabled] = useState(true);
    const [resultCount, setResultCount] = useState(0);
    const {selectionProperties, SetEnquiryGridSelectionProperties} = useContext(EnquiryGridSelectionContext)
    searchResults !== null && Object.entries(searchResults.Query).filter(x => x[1].Enabled && x[1].Value !== undefined).forEach((item)=>{
        let displayValue: string | undefined = undefined;
        const value = item[1].Value;

        if (item[1].Label === "Enquiry Status") {
            const status = Object.entries(Statuses).map(x => x[1] as Status).find(x => x.ID === parseInt(value))
            displayValue = status!.Name;
        } else if (typeof value === "string" && value.length > 0) {
            displayValue = value;
        } else if (typeof value === "number") {
            displayValue = value.toString();
        } else if (value.id !== undefined && value.firstname !== undefined) {
            displayValue = value.firstname + " " + value.lastname;
        }

        if (displayValue !== undefined) {
            sValues.push(<div className="g-badge" key={item[1].Label.toString().replace(" ", "_")}>{item[1].Label}: {displayValue}</div>)
        }
    });

    useEffect(() => {
        setResultCount(getActiveListEnquiries()?.length ?? 0);
    }, [enquiriesCache]);

    async function refreshInterface() {
        if (enabled) {
            setEnabled(false);

            resetSort();
            await ReloadSearchResults();
            dispatch(setSearchResultsVisibility());
            
            setTimeout(() => {
                setEnabled(true);
            }, 5000);
        }
        else {
            showHideLoader("flex");
            setTimeout(() => {
                showHideLoader("none");
            }, 1000);
        }
    }

    function back() {
        dispatch(setShowEnquiry(false));
        dispatch(setSearchResultsVisibility());
    }
    async function displayActiveEnquiry(){
        dispatch(setShowEnquiry(true));
    }

    useWindowResize(burgerMenuMatchLeftPanePos)
    useEffect(burgerMenuMatchLeftPanePos, [])
    return (
    <> <div className="enquiries-navigation" id="MyEnquiriesHeader">

                {
(activeEnquiry.id>11 &&  FullWidth==false && tabState?.enquiryId!>11) &&

      <>
      <div className="col-xs-5">
          <div className="enquiries-tabs">
              
          Enquiry Wizard - {eNQ?.enquiryReference + " - " + eNQ?.subject}
          </div>
      </div>
      <div className="col-xs-7">
          {RestrictedWidthState.showWizard &&<>
              <button className="g-tab g-default-tab-padding" onClick={()=>{dispatch(setShowWizard(false))}}>Selected Enquiry</button>
              <button className="g-tab-selected g-default-tab-padding" >Active Wizard</button></>
          }
          {!RestrictedWidthState.showWizard &&<>
              <button className="g-tab-selected g-default-tab-padding" >Selected Enquiry</button>
              <button className="g-tab g-default-tab-padding" onClick={()=>{dispatch(setShowWizard(true))}}>Active Wizard</button></>
          }
          </div>
      </>
   }
       {( FullWidth == false && !RestrictedWidthState.showEnquiry) &&<>
           <div className="col-xs-5">
                <div className="enquiries-tabs">
                <div style={{ float: "left", marginRight: "8px" }}>{resultCount > 0 && `Showing ${resultCount} result(s)`}</div>  
                </div>
            </div>
            <div style={{display:"flex", alignItems:"center"}} className="col-xs-7" >
                     {selectionProperties.multiSelect ? <div style={{float:"right", fontSize:"9pt", margin:"1px 5px"}}>{selectionProperties.selectedEnquiries.length} selected of {getActiveListEnquiries()?.length} </div> :""}
                         <button className="g-tab-selected g-default-tab-padding">Enquiry List</button>
                         <button className="g-tab g-default-tab-padding" onClick={()=>{displayActiveEnquiry()}}>Selected Enquiry</button>
                         <div  className={selectionProperties.multiSelect == false? "multiSelectIcon": "multiSelectIcon-selected"} >
                    <BsCheck2Square style={{width:"14px", height:"14px"}} title={ "Select Multiple"  }  onClick={()=>{
                     SetEnquiryGridSelectionProperties(currentState=>{
                                 currentState = JSON.parse(JSON.stringify(currentState));
                                 currentState.multiSelect =  currentState.multiSelect == false? true: false;
                                 currentState.selectedEnquiries = currentState.multiSelect == false? [] : currentState.selectedEnquiries
                             return currentState;
                             }) ;
                     }}></BsCheck2Square> 
                 </div> <EnquiryBurgerMenu
                             key={"burger-menu-dropdown"}
                             enquiryIds={!selectionProperties.multiSelect ? [activeEnquiry.id]: selectionProperties.selectedEnquiries.map(x=>parseInt(x))}
                         />
                    
                     </div>
          
          </>
          
       }  
        {( FullWidth == false && RestrictedWidthState.showEnquiry && tabState?.enquiryId!<11) &&<>
           <div className="col-xs-5">
                <div className="enquiries-tabs">
                    
                <div style={{ float: "left", marginRight: "8px" }}>{resultCount > 0 && `Showing ${resultCount} result(s)`}</div>  
                </div>
            </div>
              <div className="col-xs-7 d-flex align-items-center">
                  <button className="g-tab g-default-tab-padding" onClick={()=>{back()}}>Enquiry List</button>
                  <button className="g-tab-selected g-default-tab-padding" >Selected Enquiry</button>
                <EnquiryBurgerMenu
                    key={"burger-menu-dropdown"}
                    enquiryIds={!selectionProperties.multiSelect ? [activeEnquiry.id]: selectionProperties.selectedEnquiries.map(x=>parseInt(x))}
                />
          </div></>
          
       }        
   {( FullWidth == true) &&
                <div className="col-xs-5">
                    <div className="enquiries-tabs">
                        <button className="enquiry-refresh-button" title="Refresh" onClick={refreshInterface}>
                            <BsArrowClockwise style={{ float: "left", cursor: "pointer", rotate: "90deg", width: "14px", height: "14px" }} ></BsArrowClockwise>
                        </button>
                        <div style={{ float: "left", marginRight: "8px", marginLeft:"4px" }}>{resultCount > 0 && `Showing ${resultCount} result(s)`}</div>  
                </div>
            </div>
       }
 
</div>
    </>
    
    );
}

export default Query;