import * as React from "react";
import { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../state/store";

import { EnquiryGridSelectionContext, gridSelectionProperties } from "../../../contexts/enquiry-grid-selection.context";
import { iEnquiry } from "../../../interfaces/iEnquiry";
import { getLastTouched, saveLastTouched } from "../../../Logic/LastTouched/misc";
import { getActiveListEnquiries, saveActiveListEnquiries } from "../../../utilities/localStore/calls";
import { SortEnquiriesParams, sortByField, sortOwner, sortStatus } from "../../../utilities/sort";
import EnquiryGrid from "../enquiryGrid";
import "./Queues.css";

function QueueLists(props: any) {
    const activeEnquiry = useSelector((state: RootState) => state.activeEnquiry);
    const [allEnquiries, setAllenquiries] = React.useState<Array<iEnquiry>>([]);
    const enquiriesCache = useSelector((state: RootState) => state.EnquiryCache.data);
    const [sortBy, setSortBy] = React.useState<SortEnquiriesParams>();
    const { selectionProperties, SetEnquiryGridSelectionProperties } = useContext(EnquiryGridSelectionContext);

    useEffect(() => {
        const activeList = getActiveListEnquiries() ?? [];

        const enqCache = new Map(enquiriesCache.map(x => [x.id, x]));
        const updatedEnquiries = activeList
            .map(id => enqCache.get(id))
            .filter(x => x)
            .map(x => x!);

        const LastTouched = getLastTouched();
        const lastSort = LastTouched.Queue.EnquiryLists.find(l => l.Name == LastTouched.Queue.ActiveList)?.orderBy ?? { orderBy: "dateReceived", desc: true };

        setAllenquiries(updatedEnquiries);
        setSortBy(lastSort);

        if (selectionProperties.multiSelect) {
            const updatedEnqListIds = new Set<string>(updatedEnquiries.map(x => x.id.toString())); // did this for speed.
            const updatedList = updatedEnqListIds.intersection(new Set<string>(selectionProperties.selectedEnquiries));

            if (updatedList) {
                SetEnquiryGridSelectionProperties(currentState => {
                    return { ...currentState, selectedEnquiries: Array.from(updatedList) };
                });
            }
        }
    }, [enquiriesCache]);

    useEffect(() => {
        if (selectionProperties.multiSelect && selectionProperties.selectedEnquiries.length == 0) {
            SetEnquiryGridSelectionProperties(currentState => {
                currentState = JSON.parse(JSON.stringify(currentState));
                currentState.multiSelect = currentState.multiSelect;
                currentState.selectedEnquiries = [activeEnquiry.id.toString()];
                return currentState;
            });
        }
    }, [selectionProperties.multiSelect]);
    
    const handleHeaderClick = (fieldName: string): any => {
        let updatedEnquires = [...allEnquiries];
        const lastTouched = getLastTouched();
        const activeList = lastTouched.Queue.EnquiryLists.find(l => l.Name == lastTouched.Queue.ActiveList)!;

        activeList.orderBy ??= { orderBy: null };

        if (activeList.orderBy.orderBy == fieldName) {
            activeList.orderBy = {
                orderBy: fieldName,
                desc: !activeList.orderBy.desc,
            };
        } else {
            activeList.orderBy = {
                orderBy: fieldName,
            };
        }

        const order = activeList.orderBy;
        if (order.orderBy) {
            if (order.orderBy == "owner") {
                updatedEnquires = sortOwner(updatedEnquires, order.desc);
            } else if (order.orderBy == "status") {
                updatedEnquires = sortStatus(updatedEnquires, order.desc);
            } else {
                updatedEnquires = sortByField(updatedEnquires, order.orderBy, order.desc);
            }

            saveActiveListEnquiries(updatedEnquires.map(x => x.id));
            setAllenquiries(updatedEnquires);
        }

        saveLastTouched(lastTouched);
        setSortBy(activeList.orderBy);
    };

    const multiSelectOnclick = (selectionProperties: gridSelectionProperties) => {
        SetEnquiryGridSelectionProperties(currentState => {
            currentState = JSON.parse(JSON.stringify(currentState));
            currentState.multiSelect = selectionProperties.multiSelect;
            currentState.selectedEnquiries = selectionProperties.selectedEnquiries;
            return currentState;
        });
    };

    return (
        <>
            {allEnquiries.length > 0 && (
                <EnquiryGrid
                    data={allEnquiries}
                    theme="dataTable_default"
                    sortFunction={handleHeaderClick}
                    sortCol={sortBy}
                    selectedEnquiry={activeEnquiry.id}
                    columns={[
                        "verticalSPacer",
                        "priority",
                        "hasAlarm",
                        "hasUnworkedAttachments",
                        "enquiryReference",
                        "owner",
                        "enquirerName",
                        "dateReceived",
                        "activeSlaCycle.timeout",
                        "status",
                        "subject",
                        "workItems",
                    ]}
                    multiSelect={selectionProperties.multiSelect}
                    selectedItems={selectionProperties.selectedEnquiries}
                    updateSelectedItems={multiSelectOnclick}
                ></EnquiryGrid>
            )}
            {allEnquiries.length == 0 && <div style={{ padding: "12px", fontSize: "9pt" }}>No enquiries found</div>}
        </>
    );
}

export default QueueLists;
