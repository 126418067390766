import { useContext, useEffect, useState } from 'react';
import { BarChart2, ChevronsRight, MessageSquare, Search, Trello, User } from 'react-feather';
import { useSelector } from "react-redux";
import { EnquiryGridSelectionContext } from '../../../contexts/enquiry-grid-selection.context';
import { adjustHamburgerMenu } from '../../../Logic/BurgerMenu';
import { LoadMailbox, LoadSearchResults, LoadUnresolved } from '../../../Logic/Enquiries/Load';
import { getLastTouched, saveLastTouched } from '../../../Logic/LastTouched/misc';
import { setShowEnquiry, setShowWizard } from '../../../state/restrictedViewSlice';
import { setEnquiriesVisibility, setQueuesVisibility, setSearchResultsVisibility } from '../../../state/siteSlice';
import { RootState, useAppDispatch } from '../../../state/store';
import { switchFirstTab } from '../../../state/tabRow';
import { setItemEnabled, showHideLoader } from '../../../utilities/loading';
import { resetSort } from '../../../utilities/sort';
import './sidebar.scss';
import { saveActiveListEnquiries } from '../../../utilities/localStore/calls';

function Sidebar(props: any) {
    const [expanded, setExpanded] = useState(false);
    const user = useSelector((state: RootState) => state.UserInformation);
    const SiteInformation = useSelector((state: RootState) => state.SiteInformation);
    const permissions = useSelector((state: RootState) => state.permissions);
    const {selectionProperties, SetEnquiryGridSelectionProperties} = useContext(EnquiryGridSelectionContext);
    const dispatch = useAppDispatch();
    useEffect(() => {
        adjustHamburgerMenu(expanded);
    }, [expanded])

    async function showSearchResults() {
        if (SiteInformation.searchResultsVisible) {
            return;
        }

        const lastTouched = getLastTouched();
        lastTouched.lastActiveListType = "Results";
        saveLastTouched(lastTouched);

        SetEnquiryGridSelectionProperties({ multiSelect: false, selectedEnquiries: [] });
        await LoadSearchResults();
        
        dispatch(setShowEnquiry(false));
        dispatch(setShowWizard(false));
        dispatch(switchFirstTab("Search Results"));
        dispatch(setSearchResultsVisibility());
    }

    async function showQLists() {
        if (SiteInformation.queuesListVisible) {
            return;
        }
        
        const lastTouched = getLastTouched();
        const mailbox = lastTouched.Queue.ActiveList;
        lastTouched.lastActiveListType = mailbox.toString();
        saveLastTouched(lastTouched);

        SetEnquiryGridSelectionProperties({ multiSelect: false, selectedEnquiries: [] });
        resetSort();
        await LoadMailbox(mailbox);

        dispatch(setShowEnquiry(false));
        dispatch(setShowWizard(false));
        dispatch(switchFirstTab("Mailboxes"));
        dispatch(setQueuesVisibility());
    }

    async function showMyEnquiryLists() {
        if (SiteInformation.enquiriesListVisible) {
            return;
        }

        const lastTouched = getLastTouched();
        lastTouched.lastActiveListType = lastTouched.MyEnquiries.ActiveList;
        saveLastTouched(lastTouched);

        SetEnquiryGridSelectionProperties({ multiSelect: false, selectedEnquiries: [] });
        resetSort();
        await LoadUnresolved();
        
        dispatch(setShowEnquiry(false));
        dispatch(setShowWizard(false));
        dispatch(switchFirstTab("My Enquiries"));
        dispatch(setEnquiriesVisibility());
    }

    function toggleSideNav() {
        setExpanded(current => !current);
    }

    return (
        <div className={"side-nav" + (expanded ? " expanded" : "")}>
            <div className="logo-row">
                <div className="logo-icon">
                    <div className="logo-bg">

                    </div>
                </div>
                <div className="logo-label text-line-1 label">&nbsp;</div>
                <div className="nav-btns">
                    {/*<X className='nav-btn collapse-nav' onClick={collapseSideNav} />
                    <BarChart2 className='nav-btn expand-nav' onClick={expandSideNav} />*/}
                </div>
            </div>
            <div className="side-nav--links">
                {
                    permissions.Tenant.ViewMyEnquiries &&
                    <div className={SiteInformation.enquiriesListVisible ? "side-nav--links--link active" : "side-nav--links--link"}>
                        <div className="side-nav--links--link-wrap" id='side-nav-link--my-enquiries' onClick={showMyEnquiryLists}>
                            <div className="side-nav--links--link--icon">
                                <MessageSquare className="nav-icon" />
                            </div>
                            <div className="side-nav--links--link--label text-line-1 label">My Enquiries</div>
                        </div>
                    </div>
                }
                {
                    permissions.AnyMailbox.ViewEnquiryList &&
                    <div className={SiteInformation.queuesListVisible ? "side-nav--links--link active" : "side-nav--links--link"}>
                        <div className="side-nav--links--link-wrap" id='side-nav-link--mailboxes' onClick={showQLists}>
                            <div className="side-nav--links--link--icon">
                                <Trello className="nav-icon" />
                            </div>
                            <div className="side-nav--links--link--label text-line-1 label">Mailboxes</div>
                        </div>
                    </div>
                }
                {
                    permissions.Tenant.Search &&
                    <div className={SiteInformation.searchResultsVisible ? "side-nav--links--link active" : "side-nav--links--link"}>
                        <div className="side-nav--links--link-wrap" id='side-nav-link--searchResults' onClick={showSearchResults}>
                            <div className="side-nav--links--link--icon">
                                <Search className="nav-icon" />
                            </div>
                            <div className="side-nav--links--link--label text-line-1 label">Search Results</div>
                        </div>
                    </div>
                }
                <div className="side-nav--links--link">
                    <div className="side-nav--links--link-wrap">
                        <div className="side-nav--links--link--icon">
                            <BarChart2 className="nav-icon" />
                        </div>
                        <div className="side-nav--links--link--label text-line-1 label">Reporting</div>
                    </div>
                </div>

                <div className="side-nav--links--link">
                    <div className="side-nav--links--link-wrap">
                        <div className="side-nav--links--link--icon">
                            <User className="nav-icon" />
                        </div>
                        <div className="side-nav--links--link--label text-line-1 label">Contacts</div>
                    </div>
                </div>


            </div>
            <div style={{height:"100%",display:"flex",flexDirection: "column", justifyContent:"flex-end"}}>
                 <div className="ExpandContractRow" onClick={toggleSideNav}> <ChevronsRight className="ExpandIcon" ></ChevronsRight></div>
                 <div className="EMSLogo"> &nbsp; {expanded && <div className="EMS-Version">{process.env.REACT_APP_VERSION}</div>}</div>
            </div>
        </div>
    );
}

export default Sidebar;


