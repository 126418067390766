import CommPreview from '../../Email/switch'
import { iEnquiry } from "../../../interfaces/iEnquiry";
import Workflow from "../../Workflow";
import './popped-wizard.scss';
import { getSessionValue } from '../../../utilities/localStore/calls';
import { iWizards } from '../../../interfaces/wizard';
import { keepTheConnectionAlive } from '../../../utilities/userProcessing';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from '../../../error-boundary/errorFallback';
import { defaultReset } from '../../../error-boundary/logerror';
import { addConfirm, removeConfirm } from '../../../utilities/windowConfirm';
import { useEffect } from 'react';
 
function PoppedWizard(props: any) {
    const allWizards = getSessionValue<iWizards>("poppedWizards");
    let eId = window.location.href.substring(window.location.href.lastIndexOf("/")+1);
    let enQ: iEnquiry = allWizards?.wizards?.find(x=> x.id == parseInt(eId!))?.enquiry!;
    if (enQ !== undefined) {
        keepTheConnectionAlive(enQ.id);
    }

    useEffect(() => {
        addConfirm();

        return removeConfirm;
    }, []);
    
    return (
        <>
            {enQ ?
                <div className='popped-wizard-wrap'>
                <div className="popped-wizard scroll-small">
                    <div className="popped-wizard--tabs">
                        <div className="popped-wizard--tabs--head">
                            <div className="popped-wizard--tabs--head--title">
                                Enquiry Wizard - {enQ.enquiryReference} - {enQ.subject}
                            </div>
                        </div>
                    </div>
                    <div className="popped-wizard--content scroll-small">
                        <div className="popped-wizard--workflow scroll-small">
                            <ErrorBoundary
                                    FallbackComponent={ErrorFallback}
                                    onReset={defaultReset} 
                                >
                                <Workflow Popped={true} FullWidth={true} Enquiry={enQ} ></Workflow>
                            </ErrorBoundary>
                        </div>
                        
                        <div className="popped-wizard--preview scroll-small" style={{      padding:"16px",paddingTop:"0px"}}>
                            <ErrorBoundary
                                FallbackComponent={ErrorFallback}
                                onReset={defaultReset} 
                            >
                            <CommPreview FullWidth={true} enquiry={enQ} hasActivateWiz={false} />
                            </ErrorBoundary>
                        </div>
                    </div>
                </div>
                </div> : <div>Blank wizard.</div>}
        </>
    )
}

export default PoppedWizard;