import { Buffer } from "buffer";
import { ConstructDefaultMailboxPermissions, ConstructDefaultTenantPermissions, MailboxPermissionMap, MailboxPermissions, TenantPermissions, Permissions as iPermissions } from "../interfaces/Permissions";
import { decodeBase64AndUnzip } from "./gZip";
import { isNullOrWhitespace } from "./string";

class PermissionsOffsets {
    static TenantPermissions = class {
        static WorkEnquiryAnyOrder = 0;
        static Search = 1;
        static SearchMailBody = 2;
        static ViewMyEnquiries = 3;
        static ViewMyEnquiriesForUser = 4;
        static ViewCalendar = 5;
        static ViewCalendarForUser = 6;
        static LogDowntime = 7;
        static LogDowntimeForUser = 8;
    }
    static MailboxPermissions = class {
        static PopEnquiry = 0;
        static ModifyEnquiryOwnership = 1;
        static ModifyEnquiryStatus = 2;
        static ModifyEnquiryPriority = 3;
        static CloseEnquiry = 4;
        static TakeEnquiryOwnership = 5;
        static ReplaceEnquiryOwnership = 6;
        static ActionEnquiry = 7;
        static MailboxAdmin = 8;
        static ViewEnquiryList = 9;
        static ViewReports = 10;
        static BypassOwnershipNote = 11;
        static ActionOtherOwnedEnquiry = 12;
        static AllowCreateUnowned = 13;
        static ModifyEnquiryProperties = 14;
        static MembershipAdmin = 15;
        static CreateEnquiry = 16;
    }
}

export function ConstructPermissions(token: string): iPermissions {
    const [tenantAccess, mailboxAccess] = ExtractPermissionClaims(token);

    const tenantPermissions = DecodeTenantPermissions(tenantAccess);
    const [anyMailboxPermissions, mailboxPermissionMap] = DeserializeMailboxPermissions(mailboxAccess);

    return {
        Tenant: tenantPermissions,
        Mailboxes: mailboxPermissionMap,
        AnyMailbox: anyMailboxPermissions
    };
}

function ExtractPermissionClaims(token: string): [number, string] {
    let base64 = token.split(".")[1]
        .replace(/-/g, '+')
        .replace(/_/g, '/');

    var padding = (4 - (base64.length % 4)) % 4;
    base64 += "=".repeat(padding);

    const tokenJson = Buffer.from(base64, 'base64').toString("utf-8");

    const jObject = JSON.parse(tokenJson);

    return [jObject.global_access, jObject.mailbox_access];
}

function DeserializeMailboxPermissions(mailboxAccess: string): [MailboxPermissions, MailboxPermissionMap[]] {

    if (isNullOrWhitespace(mailboxAccess)) {
        return [ConstructDefaultMailboxPermissions(), []];
    }

    let buffer = decodeBase64AndUnzip(mailboxAccess);
    
    const mailboxPermissions = new Array<MailboxPermissionMap>();
    let permissionSuperset: number = 0;

    while (buffer.length > 0) {
        const mailboxID = buffer.readInt32LE(0);
        const encodedMailboxPermissions = buffer.readUInt32LE(4);

        mailboxPermissions.push({
            ID: mailboxID,
            Permissions: DecodeMailboxPermissions(encodedMailboxPermissions)
        });

        permissionSuperset |= encodedMailboxPermissions;

        buffer = buffer.slice(8);
    }
    
    return [DecodeMailboxPermissions(permissionSuperset), mailboxPermissions];
}

function DecodeMailboxPermissions(permissionFlags: number): MailboxPermissions {
    let result = ConstructDefaultMailboxPermissions();

    const mailboxOffsets: [string, number][] = Object.entries(PermissionsOffsets.MailboxPermissions);

    for (const [permission, offset] of mailboxOffsets) {
        const flag = 1 << offset;

        result = {
            ...result,
            [permission]: (permissionFlags & flag) !== 0
        }
    }

    return result;
}

function DecodeTenantPermissions(permissionFlags: number): TenantPermissions {
    let result = ConstructDefaultTenantPermissions();

    if (!permissionFlags || permissionFlags === 0) {
        return result;
    }

    const tenantOffsets: [string, number][] = Object.entries(PermissionsOffsets.TenantPermissions);

    for (const [permission, offset] of tenantOffsets) {
        const flag = 1 << offset;

        result = {
            ...result,
            [permission]: (permissionFlags & flag) !== 0
        }
    }

    return result;
}